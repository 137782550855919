import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticStyle: { "min-height": "400px" } },
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [_vm._v("Promotions")])
      ]),
      _c(
        "div",
        { staticClass: "v-card-content" },
        [
          _c(
            VLayout,
            { staticStyle: { "margin-bottom": "30px" } },
            [
              _c(VDataTable, {
                attrs: {
                  "no-data-text": _vm.loading
                    ? "Please wait while data is loading"
                    : "No promotions found",
                  headers: _vm.headers,
                  items: _vm.availablePromos,
                  loading: _vm.loading,
                  width: "920px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c(
                          "tr",
                          {
                            staticStyle: { width: "950px !important" },
                            on: {
                              mouseover: function($event) {
                                _vm.showActions = true
                              },
                              mouseleave: function($event) {
                                _vm.showActions = false
                              }
                            }
                          },
                          [
                            _c("td", [_vm._v(_vm._s(props.item.name))]),
                            _c("td", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    props.item.discountType === "AMOUNT"
                                      ? "$"
                                      : ""
                                  ) +
                                  _vm._s(_vm.getPromoAmount(props.item)) +
                                  _vm._s(
                                    props.item.discountType === "PERCENT"
                                      ? "%"
                                      : ""
                                  ) +
                                  "\n            "
                              )
                            ]),
                            _c("td", [
                              props.item.active
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "active-promotion Body-1-Selected-On-Secondary-High-Emphasis-Left"
                                    },
                                    [
                                      _vm._v(
                                        "\n                Active\n              "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _c(
                              "td",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isAdmin,
                                    expression: "isAdmin"
                                  }
                                ]
                              },
                              [
                                _c(
                                  VBtn,
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showActions,
                                        expression: "showActions"
                                      }
                                    ],
                                    staticClass: "ma-0",
                                    attrs: { flat: "", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.edit(props.item)
                                      }
                                    }
                                  },
                                  [
                                    _c(VIcon, { attrs: { small: "" } }, [
                                      _vm._v("mdi-pencil")
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  VBtn,
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showActions,
                                        expression: "showActions"
                                      }
                                    ],
                                    staticClass: "ma-0",
                                    attrs: { flat: "", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.remove(props.item)
                                      }
                                    }
                                  },
                                  [
                                    _c(VIcon, { attrs: { small: "" } }, [
                                      _vm._v("mdi-delete")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            VFooter,
            {
              staticStyle: { background: "white !important" },
              attrs: { absolute: "", height: "60" }
            },
            [
              _c(
                VLayout,
                { attrs: { row: "" } },
                [
                  _c(VFlex, { attrs: { xs11: "" } }),
                  _c(
                    VFlex,
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        VBtn,
                        {
                          staticStyle: { "margin-left": "30px" },
                          attrs: { flat: "", color: "blue" },
                          on: { click: _vm.createPromo }
                        },
                        [_vm._v("CREATE PROMO")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { persistent: "", width: "600" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(VCardTitle, { staticClass: "H5-Primary-Left" }, [
                    _vm._v("Edit Promotion")
                  ]),
                  _c(
                    VForm,
                    { ref: "form", staticClass: "py-2 px-3" },
                    [
                      _c(
                        VLayout,
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c(VTextField, {
                                attrs: {
                                  label: "Promotion Name",
                                  rules: _vm.nameRules
                                },
                                model: {
                                  value: _vm.promoEdit.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.promoEdit, "name", $$v)
                                  },
                                  expression: "promoEdit.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            {
                              staticStyle: { "padding-right": "25px" },
                              attrs: { xs8: "" }
                            },
                            [
                              _c(VSelect, {
                                attrs: {
                                  label: "Promotion Type",
                                  disabled: !!_vm.promoEdit.id,
                                  items: _vm.promoTypes,
                                  rules: _vm.promoTypeRules
                                },
                                model: {
                                  value: _vm.promoEdit.discountType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.promoEdit, "discountType", $$v)
                                  },
                                  expression: "promoEdit.discountType"
                                }
                              })
                            ],
                            1
                          ),
                          !_vm.promoEdit.code
                            ? _c(
                                VFlex,
                                { attrs: { xs4: "" } },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Value ($ or %)",
                                      disabled: !!_vm.promoEdit.id,
                                      type: "number",
                                      rules: _vm.promoValueRules
                                    },
                                    model: {
                                      value: _vm.promoEdit.amount,
                                      callback: function($$v) {
                                        _vm.$set(_vm.promoEdit, "amount", $$v)
                                      },
                                      expression: "promoEdit.amount"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          !!_vm.promoEdit.code
                            ? _c(
                                VFlex,
                                { attrs: { xs4: "" } },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Value ($ or %)",
                                      value: _vm.getPromoAmount(_vm.promoEdit),
                                      disabled: !!_vm.promoEdit.id,
                                      type: "number",
                                      rules: _vm.promoValueRules
                                    },
                                    on: {
                                      input: function(amount) {
                                        return _vm.setPromoAmount(
                                          _vm.promoEdit,
                                          amount
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c(VSelect, {
                                attrs: {
                                  label: "Promotion State",
                                  items: _vm.promoActiveStates,
                                  rules: _vm.promoStateRules
                                },
                                model: {
                                  value: _vm.promoEdit.active,
                                  callback: function($$v) {
                                    _vm.$set(_vm.promoEdit, "active", $$v)
                                  },
                                  expression: "promoEdit.active"
                                }
                              })
                            ],
                            1
                          ),
                          _c(VFlex, { attrs: { xs12: "" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "Body-1-Selected-On-Surface-Medium-Emphasis-Left"
                              },
                              [
                                _vm._v(
                                  "\n                Promotion applies to these payment methods\n              "
                                )
                              ]
                            )
                          ]),
                          _c(
                            VFlex,
                            {
                              staticClass: "flex-col-reverse",
                              attrs: { xs12: "", nowrap: "" }
                            },
                            [
                              _vm.appName === "thrive"
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "nowrap"
                                      }
                                    },
                                    [
                                      _c(VCheckbox, {
                                        attrs: {
                                          label: "Badge Pay Member Account"
                                        },
                                        model: {
                                          value:
                                            _vm.promoEdit.is_badgepay_promo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promoEdit,
                                              "is_badgepay_promo",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "promoEdit.is_badgepay_promo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(VCheckbox, {
                                    attrs: { label: "Mealplans" },
                                    model: {
                                      value: _vm.promoEdit.is_mealplan_promo,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promoEdit,
                                          "is_mealplan_promo",
                                          $$v
                                        )
                                      },
                                      expression: "promoEdit.is_mealplan_promo"
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardActions,
                    { staticStyle: { padding: "15px" } },
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", flat: "" },
                          on: { click: _vm.cancelEdit }
                        },
                        [_vm._v("cancel")]
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.saveChanges }
                        },
                        [_vm._v("save changes")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }