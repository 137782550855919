import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [
          _vm._v("Location Details")
        ])
      ]),
      _vm.timezone
        ? _c("div", { staticClass: "timezone" }, [
            _vm._v("Timezone: " + _vm._s(_vm.timezone))
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "v-card-content" },
        [
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs12: "", md7: "" } },
                [
                  _c(
                    VLayout,
                    { staticClass: "wrap" },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              label: "Location Name",
                              rules: _vm.nameRules,
                              disabled: _vm.isDH
                            },
                            model: {
                              value: _vm.value.name,
                              callback: function($$v) {
                                _vm.$set(_vm.value, "name", $$v)
                              },
                              expression: "value.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c("location-autocomplete", {
                            attrs: {
                              rules: _vm.addressRules,
                              disabled: _vm.isDH
                            },
                            on: { autocomplete: _vm.setPlace },
                            model: {
                              value: _vm.value.address.address,
                              callback: function($$v) {
                                _vm.$set(_vm.value.address, "address", $$v)
                              },
                              expression: "value.address.address"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                {
                  staticStyle: { "padding-left": "35px" },
                  attrs: { xs12: "", md5: "" }
                },
                [
                  !_vm.isDH
                    ? _c("image-uploader", {
                        ref: "logoImage",
                        attrs: {
                          imageUrl: _vm.logoImageProxy,
                          width: 324,
                          height: 143
                        },
                        on: {
                          "update:imageUrl": function($event) {
                            _vm.logoImageProxy = $event
                          },
                          "update:image-url": function($event) {
                            _vm.logoImageProxy = $event
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            VLayout,
            [
              !_vm.isDH
                ? _c(
                    VFlex,
                    { attrs: { xs7: "" } },
                    [
                      _c(VSelect, {
                        attrs: { items: _vm.sectorNames, label: "Sector" },
                        model: {
                          value: _vm.sectorName,
                          callback: function($$v) {
                            _vm.sectorName = $$v
                          },
                          expression: "sectorName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(VFlex, { attrs: { xs5: "" } })
            ],
            1
          ),
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs7: "" } },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.countries,
                      label: "Country",
                      placeholder: "Canada",
                      rules: _vm.countryRules,
                      disabled: _vm.isDH
                    },
                    model: {
                      value: _vm.value.address.country,
                      callback: function($$v) {
                        _vm.$set(_vm.value.address, "country", $$v)
                      },
                      expression: "value.address.country"
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs5: "" } },
                [
                  _c(VSelect, {
                    staticClass: "ml-4",
                    attrs: {
                      items: _vm.states,
                      label: "State",
                      placeholder: "Choose State",
                      rules: _vm.stateRules,
                      disabled: _vm.isDH || _vm.states.length === 0
                    },
                    model: {
                      value: _vm.value.address.state,
                      callback: function($$v) {
                        _vm.$set(_vm.value.address, "state", $$v)
                      },
                      expression: "value.address.state"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs7: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "City",
                      rules: _vm.cityRules,
                      disabled: _vm.isDH
                    },
                    model: {
                      value: _vm.value.address.city,
                      callback: function($$v) {
                        _vm.$set(_vm.value.address, "city", $$v)
                      },
                      expression: "value.address.city"
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs5: "" } },
                [
                  _c(VTextField, {
                    staticClass: "ml-4",
                    attrs: {
                      label: _vm.codeLabel,
                      rules: _vm.addressCodeRules,
                      disabled: _vm.isDH
                    },
                    model: {
                      value: _vm.value.address.zip,
                      callback: function($$v) {
                        _vm.$set(_vm.value.address, "zip", $$v)
                      },
                      expression: "value.address.zip"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs7: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "Latitude, Longitude",
                      rules: _vm.latlongRules,
                      disabled: _vm.isDH
                    },
                    model: {
                      value: _vm.latLong,
                      callback: function($$v) {
                        _vm.latLong = $$v
                      },
                      expression: "latLong"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(VFlex, { attrs: { xs6: "" } }),
          !_vm.isDH
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "my-3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "H6-Selected-On-Surface-High-Emphasis-Left"
                        },
                        [_vm._v("On Site Product")]
                      ),
                      _c(VTextField, {
                        staticClass: "App-Not-Selected-Error",
                        attrs: {
                          rules: _vm.appSelectionRules,
                          value: _vm.value.app
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            VLayout,
                            {
                              attrs: { "justify-start": "", row: "", wrap: "" }
                            },
                            _vm._l(_vm.multigroups, function(app, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "mb-3 mr-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "App-Container",
                                      class: {
                                        "Selected-App":
                                          app.id === _vm.value.app,
                                        "disabled-app":
                                          app.id !== _vm.value.app &&
                                          _vm.value.app ===
                                            _vm.$route.params.app
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.select(app.id)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "App-Image-Box" },
                                        [
                                          app.id === _vm.value.app
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "Selected-Icon-Box"
                                                },
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "Selected-Icon"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-checkbox-marked-circle"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "App-Image" },
                                            [
                                              _c(VImg, {
                                                attrs: {
                                                  src: require("@/assets/app-logo.svg"),
                                                  width: "158px",
                                                  height: "158px"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "py-2 pl-2",
                                          class: {
                                            "Body-1-Primary-Left":
                                              app.id === _vm.value.app,
                                            "Body-1-Selected-On-Surface-Medium":
                                              app.id !== _vm.value.app
                                          },
                                          staticStyle: {
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(app.name) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isNewSite || _vm.isExternalPartner
                    ? _c(
                        VFlex,
                        { attrs: { xs5: "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "Subtitle-1-Black-Medium-Emphasis-Left"
                            },
                            [_vm._v("Is this a non-CDL site?")]
                          ),
                          _c("toggle", {
                            attrs: {
                              disabled: !_vm.value.app || !_vm.isNewSite,
                              position: "start"
                            },
                            model: {
                              value: _vm.isExternalPartner,
                              callback: function($$v) {
                                _vm.isExternalPartner = $$v
                              },
                              expression: "isExternalPartner"
                            }
                          }),
                          _vm.isExternalPartner
                            ? _c(VTextField, {
                                attrs: {
                                  label:
                                    "Partner name (defaults to multigroup name lowercase) ",
                                  rules: _vm.externalPartnerRules(
                                    _vm.value.meta.external_partner
                                  ),
                                  disabled: !_vm.isNewSite
                                },
                                model: {
                                  value: _vm.value.meta.external_partner,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.value.meta,
                                      "external_partner",
                                      $$v
                                    )
                                  },
                                  expression: "value.meta.external_partner"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }