/* eslint-disable camelcase */
<template>
  <v-combobox
    :value="value"
    :search-input.sync="valueProxy"
    :items="locations"
    :loading="loading"
    item-text="description"
    label="Address"
    @change="selected"
    append-icon
    no-data-text="No results found"
    :rules="rules"
    :disabled="disabled"
    ref="combobox"
    @blur="blur"
  ></v-combobox>
</template>

<script>
import axios from 'axios';

const { CancelToken } = axios;

export default {
  props: ['value', 'rules', 'disabled'],
  data: () => ({
    search: '',
    locations: [],
    loading: false,
    request: null,
    requestCancel: null,
  }),
  computed: {
    valueProxy: {
      get() {
        return this.value;
      },
      set(v) {
        if (v !== null) this.$emit('input', v);
      },
    },
  },
  watch: {
    async valueProxy(val) {
      if (this.requestCancel) {
        this.requestCancel();
      }
      if (val) {
        this.loading = true;
        this.request = axios.get(
          'https://zpnwqjg9n4.execute-api.us-east-1.amazonaws.com/default/google-places',
          {
            params: {
              predict: val,
            },
            cancelToken: new CancelToken((c) => {
              this.requestCancel = c;
            }),
          },
        );
        this.request
          .then((e) => {
            this.locations = e.data;
            this.requestCancel = null;
            this.loading = false;
          })
          .catch((err) => {
            if (err.constructor.name !== 'Cancel') {
              this.loading = false;
            }
            this.requestCancel = null;
            // assign some sort of error
          });
      } else {
        this.locations = [];
      }
    },
  },
  methods: {
    async selected(v) {
      if (v === null) return;
      const { place_id, structured_formatting } = v;
      if (place_id) {
        this.$nextTick().then(() => {
          this.valueProxy = structured_formatting.main_text;
        });
      }
      const { data: details } = await axios.get(
        'https://zpnwqjg9n4.execute-api.us-east-1.amazonaws.com/default/google-places',
        {
          params: {
            id: place_id,
          },
        },
      );

      if (!details) {
        return;
      }

      const addressComponents = {};
      details.address_components.forEach((component) => {
        if (component.types) {
          component.types.forEach((type) => {
            addressComponents[type] = component;
          });
        }
      });
      this.$emit('autocomplete', {
        address: details.name,
        state: addressComponents.administrative_area_level_1?.short_name,
        locality: addressComponents.locality?.short_name,
        country: addressComponents.country?.short_name,
        zip: addressComponents.postal_code?.short_name,
        coordinates: details.geometry?.location,
      });
    },
    blur() {
      this.$refs.combobox.blur();
    },
  },
};
</script>

<style></style>
