import { VCombobox } from 'vuetify/lib/components/VCombobox';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VCombobox, {
    ref: "combobox",
    attrs: {
      value: _vm.value,
      "search-input": _vm.valueProxy,
      items: _vm.locations,
      loading: _vm.loading,
      "item-text": "description",
      label: "Address",
      "append-icon": "",
      "no-data-text": "No results found",
      rules: _vm.rules,
      disabled: _vm.disabled
    },
    on: {
      "update:searchInput": function($event) {
        _vm.valueProxy = $event
      },
      "update:search-input": function($event) {
        _vm.valueProxy = $event
      },
      change: _vm.selected,
      blur: _vm.blur
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }