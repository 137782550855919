<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">KDS Configuration</div>
    </v-card-title>
    <div class="v-card-content">
      <v-data-table
        :headers="headers"
        :items="unitData"
        :loading="loading"
        width="920px"
        :no-data-text="loading ? 'Please wait while data is loading' : 'No KDS devices found'"
      >
        <template slot="headerCell" slot-scope="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ props.header.text }}
              </span>
            </template>
            <span>
              {{ props.header.text }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:items="props">
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.serialNumber }}</td>
          <td>{{ props.item.unitNumber }}</td>
          <td>{{ props.item.brands }}</td>
          <td><v-btn @click="resetToken(props.item.serialNumber)">Reset</v-btn></td>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import ID from '@compassdigital/id';
import { mapState } from 'vuex';

export default {
  name: 'kds-devices',
  props: {
    kds_units: {
      default: () => {},
      type: Array,
    },
  },
  data: () => ({
    loading: false,
    headers: [
      { text: 'Station Name', value: 'name' },
      { text: 'Serial Number', value: 'serialNumber' },
      { text: 'Unit Number', value: 'unitNumber' },
      { text: 'Brands', value: 'brands' },
      { text: 'Reset Token', value: 'serialNumber' },
    ],
  }),
  computed: {
    ...mapState('sites', ['brandMap']),
    unitData() {
      const data = [];
      this.kds_units.forEach((unit) => {
        unit.kdsDevices.forEach((device) => {
          let brands = 'N/A';
          if (device.brands) {
            brands = device.brands
              .map((id) => {
                const brand = this.brandMap[id];
                return brand ? brand.name : 'Invalid';
              })
              .join(',\n');
          }
          data.push({
            serialNumber: device.serialNumber,
            name: device.name,
            unitNumber: unit.unitNumber,
            brands,
          });
        });
      });
      return data;
    },
  },

  methods: {
    showToggle(kds_units) {
      return kds_units ? kds_units.length > 0 : false;
    },

    async resetToken(serialNumber) {
      const input = await this.$confirm({
        title: 'Reset Token',
        message: `Are you sure you want to do this as it will force the user to onboard KDS again?`,
        buttonTrueText: 'YES',
        buttonFalseText: 'NO',
      });
      if (input) {
        try {
          const id = ID('kds', 'cdl', 'device', serialNumber);
          await this.api.delete(`/kds/device/auth/${id}`);
          this.$toast('Device token reset successfully.');
        } catch (err) {
          console.error(err);
          this.$toast.error('Error resetting device token.');
        }
      }
    },
  },
};
</script>
