import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [
          _vm._v("KDS Configuration")
        ])
      ]),
      _c(
        "div",
        { staticClass: "v-card-content" },
        [
          _c(VDataTable, {
            attrs: {
              headers: _vm.headers,
              items: _vm.unitData,
              loading: _vm.loading,
              width: "920px",
              "no-data-text": _vm.loading
                ? "Please wait while data is loading"
                : "No KDS devices found"
            },
            scopedSlots: _vm._u([
              {
                key: "headerCell",
                fn: function(props) {
                  return [
                    _c(
                      VTooltip,
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c("span", _vm._g({}, on), [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(props.header.text) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(props.header.text) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ]
                }
              },
              {
                key: "items",
                fn: function(props) {
                  return [
                    _c("td", [_vm._v(_vm._s(props.item.name))]),
                    _c("td", [_vm._v(_vm._s(props.item.serialNumber))]),
                    _c("td", [_vm._v(_vm._s(props.item.unitNumber))]),
                    _c("td", [_vm._v(_vm._s(props.item.brands))]),
                    _c(
                      "td",
                      [
                        _c(
                          VBtn,
                          {
                            on: {
                              click: function($event) {
                                return _vm.resetToken(props.item.serialNumber)
                              }
                            }
                          },
                          [_vm._v("Reset")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }