<template>
  <v-container grid-list-xl>
    <v-form ref="form" lazy-validation class="settings" v-model="valid">
      <v-layout row wrap>
        <v-flex xs12>
          <view-title />
          <intersection-observer @intersect="intersect" />
        </v-flex>
        <v-flex xs10>
          <v-layout row wrap>
            <v-flex xs12>
              <site-details
                v-model="site"
                id="site-detail"
                :isNewSite="isNewSite"
                v-observe-visibility="visibilityChanged"
                ref="siteDetails"
              />
            </v-flex>
            <v-flex xs12></v-flex>
            <v-flex xs12>
              <payment
                :payment="payment"
                :keystore="keystore"
                id="payment-detail"
                v-observe-visibility="visibilityChanged"
                :refund.sync="payment.refund"
                @editKeystore="editKeystore"
                @editPayment="editPayment"
                ref="paymentRef"
              />
            </v-flex>
            <v-flex xs12>
              <cashless
                v-if="isCashless"
                v-model="cashless"
                :siteId="site.id"
                :payment="payment"
                :formRef="$refs.form"
                id="cashless-pay"
                v-observe-visibility="visibilityChanged"
              />
              <!-- Badge pay to be deprecated at a later time -->
              <badge-pay
                v-else
                v-model="badge_pay"
                :siteId="site.id"
                :payment="payment"
                :formRef="$refs.form"
                :cashless.sync="cashless"
                id="badge-pay"
                v-observe-visibility="visibilityChanged"
              />
            </v-flex>
            <v-flex xs12>
              <mealplan
                :mealplan.sync="mealplan"
                :locationGroupName="site.name"
                :siteId="site.id"
                id="mealplan-detail"
                v-observe-visibility="visibilityChanged"
                configLevel="SITE"
              />
            </v-flex>
            <v-flex xs12 class="mb-5">
              <loyalty
                v-model="loyalty"
                id="loyalty-detail"
                v-observe-visibility="visibilityChanged"
              />
            </v-flex>
            <v-flex xs12 class="mb-5">
              <promotion
                :promotions.sync="promotions"
                :original-promos="backup.publicConfig.promotions"
                id="promotion-detail"
                v-observe-visibility="visibilityChanged"
              />
            </v-flex>
            <v-flex xs12 class="mb-5">
              <kds :kds_units="kds_units" id="kds-conf" />
            </v-flex>
            <v-flex xs12 class="mb-5">
              <deliveryDropoffLocations
                :drop-off-locations.sync="drop_off_locations"
                :original-drop-off-locations="backup.drop_off_locations"
                :isDisabled="!isCDLLocation"
                id="drop-off-locations"
                v-observe-visibility="visibilityChanged"
              />
            </v-flex>
            <v-flex xs12 class="mb-5">
              <apex id="apex-config" :apex.sync="apex" />
            </v-flex>
            <v-flex xs12 class="mb-5">
              <frictionless
                id="frictionless"
                :withDialog="false"
                :frictionlessProps.sync="frictionlessData"
                v-observe-visibility="visibilityChanged"
                configLevel="SITE"
              />
            </v-flex>
            <v-flex xs12 class="mb-5" v-if="isOneMarketFeatureFlagOn">
              <onemarket
                id="onemarket"
                :withDialog="false"
                :onemarketProps.sync="onemarketData"
                v-observe-visibility="visibilityChanged"
              />
            </v-flex>
            <LocalMenuGroupLink
              id="local-menu-group-link"
              v-if="isLocalMenuGroupToSiteFeatureFlagOn"
              :linkedLocalMenuGroups.sync="linkedLocalMenuGroups"
              :linkedLocalMenuGroupsBackup.sync="backup.linkedLocalMenuGroups"
            />
          </v-layout>
        </v-flex>
        <v-flex xs2>
          <tableOfContents
            v-model="tocItems"
            :customClass="tocCustomClass"
            :items="tocItems"
            :isCashless="isCashless"
            ref="toc"
          ></tableOfContents>
        </v-flex>
      </v-layout>
      <save-footer
        v-show="isModified"
        :cancelAction="cancel"
        :saveLabel="isNewSite ? 'Create Site' : 'Save Changes'"
        :saveAction="save"
      />
    </v-form>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import ID from '@compassdigital/id';
import tableOfContents from '@/components/tableOfContents';
import intersectionObserver from '@/components/intersectionObserver';
import MealplanHelperModule from '@/helpers/mealplan-helper-module';
import { MEALPLAN_TYPES, featureFlagNames } from '@/constants';
import Mealplan from '../mealplan';
import Payment from '../payment';
import BadgePay from '../badge_pay.vue';
import Cashless from '../cashless.vue';
import Loyalty from '../loyalty';
import Promotion from '../promotion';
import SiteDetails from './details';
import Kds from '../kds';
import Apex from '../apex';
import Frictionless from '../frictionless';
import Onemarket from '../onemarket';
import DeliveryDropoffLocations from '../delivery_drop_off_locations';
import LocalMenuGroupLink from '../local_menu_group_link';

export default {
  name: 'site-settings',
  async beforeRouteLeave(to, from, next) {
    if (this.isModified && !this.savedSuccessfully) {
      const input = await this.$confirm({
        title: 'Leave without saving?',
        message: 'Changes will be lost if you do not save.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    Loyalty,
    Mealplan,
    Payment,
    SiteDetails,
    tableOfContents,
    Promotion,
    Kds,
    Apex,
    DeliveryDropoffLocations,
    intersectionObserver,
    Frictionless,
    BadgePay,
    Onemarket,
    Cashless,
    LocalMenuGroupLink,
  },
  data: () => ({
    /**
     * Backup used by cache when user presses cancel
     */
    backup: {
      privateConfig: {
        loyalty: { provider_id: undefined, loyalty_emails: [], enabled: false },
        payment: { freedompay: null },
        keystore: { payment: null },
        badge_pay: {},
        supports: { reorder: false },
        mealplan: [{}],
        apex: {
          apex_integrated: false,
          apex_client_numb: '',
        },
      },
      publicConfig: {
        mealplan: [],
        promotions: [],
        badge_pay: {},
        cashless: {},
        frictionless_supported: false,
      },
      drop_off_locations: [],
      siteDetails: { address: {}, coordinates: {} },
      linkedLocalMenuGroups: [],
    },
    /**
    /**
     * Site Info
     */
    site: { address: {}, coordinates: {} },
    /**
     * Validation
     */
    valid: true,
    /*
      Private config properties
    */
    loyalty: { provider_id: undefined, loyalty_emails: [], enabled: false },
    promotions: [],
    payment: { freedompay: null },
    keystore: { payment: null },
    supports: { reorder: false },
    kds_units: [],
    apex: {
      apex_integrated: false,
      apex_client_numb: '',
    },
    frictionless: { frictionless_supported: false },
    onemarket: { partner_menu_import_enabled: false, account_operation_id: '' },
    /*
      Public config properties
    */
    mealplan: [],
    // 4 sites are using badge_pay, so we need to keep it in public config for now
    badge_pay: {},
    cashless: {},
    /**
     * Other variables
     */
    isMultigroupSelected: true,
    drop_off_locations: [],
    isHeaderVisible: false,
    linkedLocalMenuGroups: [],
    savedSuccessfully: true,
    /**
     *  TOC items
     */
    tocItems: [
      {
        id: 'site-detail',
        title: 'Location Details',
      },
      {
        id: 'payment-detail',
        title: 'Payment Info',
      },
      {
        id: 'badge-pay',
        title: 'Badge Pay',
      },
      {
        id: 'mealplan-detail',
        title: 'Mealplan Info',
      },
      {
        id: 'loyalty-detail',
        title: 'Loyalty Info',
      },
      {
        id: 'promotion-detail',
        title: 'Promotion Info',
      },
      {
        id: 'kds-conf',
        title: 'KDS Configuration',
      },
      {
        id: 'drop-off-locations',
        title: 'Delivery Drop-off Locations',
      },
      {
        id: 'apex-config',
        title: 'APEX Configuration',
      },
      {
        id: 'frictionless',
        title: 'Frictionless',
      },
    ],
  }),
  computed: {
    ...mapGetters('splitio', ['isFeatureOn']),
    ...mapGetters('sites', {
      isDHSite: 'isDHSite',
      multigroups: 'getCdlMultigroups',
    }),
    ...mapState('validateMeqSetup', ['isInValidMeq']),
    isOneMarketFeatureFlagOn() {
      return this.isFeatureOn(featureFlagNames.oneMarketIntegration);
    },
    isLocalMenuGroupToSiteFeatureFlagOn() {
      return this.isFeatureOn(featureFlagNames.localMenuGroupToSitePermissions);
    },
    isCashless() {
      return Boolean(!this.badge_pay?.id || (this.badge_pay?.id && this.cashless?.id));
    },
    isNewSite() {
      return !this.$route.params.site_id;
    },
    isCDLLocation() {
      try {
        return this.isNewSite || !this.isDHSite(this.site);
      } catch (err) {
        return false;
      }
    },
    isModified() {
      const mealplan = isEqual(this.backup.publicConfig.mealplan, this.mealplan);
      const loyalty = isEqual(this.backup.privateConfig.loyalty, this.loyalty);
      const payment = isEqual(this.backup.privateConfig.payment, this.payment);
      const keystore = isEqual(this.backup.privateConfig.keystore, this.keystore);
      const supports = isEqual(this.backup.privateConfig.supports, this.supports);
      const badge_pay = isEqual(this.backup.publicConfig.badge_pay, this.badge_pay);
      const cashless = isEqual(this.backup.publicConfig.cashless, this.cashless);
      const promotions = isEqual(this.backup.publicConfig.promotions, this.promotions);
      const site = isEqual(this.backup.siteDetails, this.site);
      const apex = isEqual(this.backup.privateConfig.apex, this.apex);
      const drop_off_locations = isEqual(this.backup.drop_off_locations, this.drop_off_locations);
      const linkedLocalMenuGroups = isEqual(
        this.backup.linkedLocalMenuGroups,
        this.linkedLocalMenuGroups,
      );
      const frictionless_supported = isEqual(
        this.backup.publicConfig.frictionless_supported,
        this.frictionless.frictionless_supported,
      );

      const onemarket_support_is_same =
        !this.isOneMarketFeatureFlagOn ||
        (isEqual(
          Boolean(this.backup.siteDetails.meta?.partner_menu_import_enabled),
          Boolean(this.onemarket.partner_menu_import_enabled),
        ) &&
          isEqual(
            this.backup.siteDetails.meta?.account_operation_id ?? '',
            this.onemarket?.account_operation_id ?? '',
          ));
      return !(
        mealplan &&
        loyalty &&
        payment &&
        keystore &&
        site &&
        supports &&
        badge_pay &&
        promotions &&
        apex &&
        drop_off_locations &&
        frictionless_supported &&
        onemarket_support_is_same &&
        cashless &&
        linkedLocalMenuGroups
      );
    },
    ...mapState('sites', ['active_site', 'multigroups', 'selected_multigroup', 'active_brand']),
    tocCustomClass() {
      // Set setting menu class to fixed position if header is not visible on the viewport
      return this.isHeaderVisible ? '' : 'fixedStyle';
    },
    frictionlessData: {
      get() {
        return this.frictionless;
      },
      set(obj) {
        this.frictionless = {
          ...this.frictionless,
          frictionless_supported: obj.frictionless_supported,
        };
      },
    },
    onemarketData: {
      get() {
        return this.onemarket;
      },
      set(obj) {
        this.onemarket = {
          ...this.onemarket,
          partner_menu_import_enabled: obj.partner_menu_import_enabled,
          account_operation_id: obj.account_operation_id,
        };
        if (!this.site.meta) {
          this.site.meta = {};
        }
        this.site.meta.partner_menu_import_enabled = obj.partner_menu_import_enabled;
        this.site.meta.account_operation_id = obj.account_operation_id;
      },
    },
  },
  async mounted() {
    const siteId = this.$route.params.site_id || null;

    if (this.isNewSite) {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: 'Add a New Site',
        to: { name: 'site-new' },
      });
      return;
    }
    this.$store.dispatch('adminPanel/setLoading', true);
    try {
      await this.fetchData();

      this.$store.commit('adminPanel/setViewTitle', {
        depth: 2,
        title: this.site.name,
        breadcrumb: 'Settings',
        to: {
          name: 'site-settings',
          params: {
            site_id: this.site.id,
          },
        },
      });
    } catch (err) {
      console.error('could not fetch data', err);
    }
    await this.getKDSDevices();
    this.$store.dispatch('adminPanel/setLoading', false);

    try {
      // check if frictionless is enabled for that site
      const frictionless_supported = await this.isSiteFrictionless(siteId);

      if (frictionless_supported) {
        this.frictionless.frictionless_supported = true;
        this.backup.publicConfig.frictionless_supported = true;
      }
      if (this.isOneMarketFeatureFlagOn) {
        if (this.site?.meta?.partner_menu_import_enabled) {
          this.onemarket.partner_menu_import_enabled = true;
        }
        if (this.site?.meta?.account_operation_id) {
          this.onemarket.account_operation_id = this.site.meta.account_operation_id;
        }
        this.tocItems.push({
          id: 'onemarket',
          title: 'OneMarket Integration',
        });
      }
      if (this.isLocalMenuGroupToSiteFeatureFlagOn) {
        this.tocItems.push({
          id: 'local-menu-group-link',
          title: 'Linked Local Menu Groups',
        });
      }
    } catch (err) {
      console.error('could not fetch frictionless config data', err);
    }
  },
  methods: {
    ...mapMutations('validateMeqSetup,', ['setInValidMeq']),
    ...mapActions('sites', [
      'patchLocation',
      'postLocationGroup',
      'getLocationGroup',
      'patchLocationGroup',
      'patchLocationMultigroup',
      'getConfigPublic',
      'getConfigPrivate',
      'postConfigPublic',
      'postConfigPrivate',
      'getDeliveryDestinations',
      'postDeliveryDestination',
      'patchDeliveryDestination',
      'deleteDeliveryDestination',
      'fetchBrand',
      'isSiteFrictionless',
    ]),
    ...mapActions('centricOSMenu', [
      'linkSiteToLocalMenuGroups',
      'removeSiteToLocalMenuGroupsLink',
    ]),
    ...MealplanHelperModule,
    async getKDSDevices() {
      const is_canadian_unit =
        this.active_site &&
        this.active_site.address &&
        this.active_site.address.country.substring(0, 2).toLowerCase() === 'ca';
      const unit_ids =
        this.active_site &&
        this.active_site.locations &&
        this.active_site.locations.map((location) =>
          is_canadian_unit
            ? location.meta && `ca${location.meta.unit_id}`
            : location.meta && location.meta.unit_id,
        );
      try {
        const { data } = await this.api.get('/kds/devices', {
          params: {
            unitNumber: unit_ids.join(','),
          },
        });
        this.kds_units = cloneDeep(data.units);
        if (this.kds_units.length > 0);
      } catch (err) {
        console.error(err);
        this.$toast.error('could not fetch kds devices');
      }
    },
    async updateDropOffLocations(siteId) {
      let updateRequests = [];
      // need to create a copy as backup locations get mutated
      const originalLocations = cloneDeep(this.backup.drop_off_locations);

      const postLocationRequests = this.drop_off_locations.reduce((postArr, location) => {
        if (location.action === 'CREATE') {
          const { action, ...newLocation } = location;
          delete newLocation.id;
          return [
            ...postArr,
            this.postDeliveryDestination({
              groupId: siteId,
              deliveryDestination: newLocation,
            }),
          ];
        }
        return postArr;
      }, []);

      if (postLocationRequests.length > 0) {
        updateRequests = updateRequests.concat(postLocationRequests);
      }

      const patchLocationsPayload = this.drop_off_locations.reduce((updateArr, location) => {
        const originalLocation = originalLocations.find((loc) => loc.id === location.id);
        const { action, ...updateLocation } = location;
        if (location.action === 'UPDATE' && !isEqual(originalLocation, updateLocation)) {
          return [...updateArr, updateLocation];
        }
        return updateArr;
      }, []);

      if (patchLocationsPayload.length > 0) {
        updateRequests.push(
          this.patchDeliveryDestination({
            groupId: siteId,
            deliveryDestinations: { delivery_destinations: patchLocationsPayload },
          }),
        );
      }

      const deleteLocationsPayload = this.drop_off_locations.reduce((deleteArr, location) => {
        if (location.action === 'DELETE') {
          return [...deleteArr, location.id];
        }
        return deleteArr;
      }, []);

      if (deleteLocationsPayload.length > 0) {
        updateRequests.push(
          this.deleteDeliveryDestination({
            groupId: siteId,
            deliveryDestinationIds: { delivery_destination_ids: deleteLocationsPayload },
          }),
        );
      }

      try {
        await Promise.all(updateRequests);
        this.backup.drop_off_locations = cloneDeep(this.drop_off_locations);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async save() {
      const isValidForm = this.validateForm();
      if (!this.valid || !isValidForm) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }

      // validate MEQ setup, check all tender
      if (
        this.mealplan &&
        this.mealplan.length > 0 &&
        this.mealplan[0].type === MEALPLAN_TYPES.ATRIUM
      ) {
        for (const tender of this.mealplan[0].private.config.tenders) {
          this.$store.commit('validateMeqSetup/setInValidMeq', tender);
          if (this.isInValidMeq) {
            this.$toast('Meal Equivalency Tender Setup Incomplete.');
            return;
          }
        }
      }

      const self = this;
      const infoNotSaved = [];
      let error = false;
      let siteId = this.$route.params.site_id || null;
      let group = null;
      let location = null;
      let site = null;

      try {
        await this.$refs.siteDetails.save();
        // have to polish the objects first
        location = {
          address: {
            ...this.site.address,
            coordinates: this.site.coordinates,
          },
          ...this.site.coordinates,
        };

        group = {
          style: this.site.style,
          address: {
            ...this.site.address,
            coordinates: {
              ...this.site.coordinates,
            },
          },
          label: {
            en: this.site.name,
          },
          meta: this.site.meta,
          name: this.site.name,
        };
        if (this.isNewSite) {
          siteId = (await this.postLocationGroup(group)).id;
          this.$set(this.site, 'id', siteId);
          await this.patchLocationMultigroup({ id: this.site.app, groups: [{ id: siteId }] });
        } else if (this.isCDLLocation) {
          // have to update everything
          await Promise.all(
            this.site.locations.map(({ id: locationId }) =>
              this.patchLocation({ ...location, id: locationId }),
            ),
          );
          group.id = siteId;
          await this.patchLocationGroup(group);
        }
        await this.$store.dispatch('sites/fetchSite', {
          id: siteId,
          multigroupId: this.site.app,
          nocache: true,
          fetchWithPermissions: true,
        });

        site = { ...this.site };
        if (!siteId) siteId = site.id;
        this.backup.siteDetails = cloneDeep(site);
      } catch (err) {
        console.error(err);
        error = true;
        infoNotSaved.push('Site Details');
      }

      let promotions = null;
      const havePromotionsChanged = !isEqual(this.backup.publicConfig.promotions, this.promotions);
      try {
        if (error) throw new Error('Error occurred, stop flow');
        // create/update/delete promos
        if (havePromotionsChanged && this.promotions) {
          const promosToUpdate = [];
          const promosToCreate = [];
          const promosToDelete = [];
          const promosWithNoUpdates = [];
          this.promotions.forEach((p) => {
            if (p.updated && p.id) promosToUpdate.push(p);
            else if (!p.id) promosToCreate.push(p);
            else promosWithNoUpdates.push(p);
          });
          const promotionsBackup = this.backup.publicConfig.promotions;
          if (promotionsBackup) {
            promotionsBackup.forEach((p1) => {
              const foundPromo = this.promotions.find((p2) => !!p2.id && p2.id === p1.id);
              if (!foundPromo) promosToDelete.push(p1);
            });
          }

          const promosToDeletePromises = promosToDelete.map((p) => {
            const params = {};
            if (p.app) params.app = p.app;
            return this.api.delete(`/promo/voucher/${p.id}`, { params });
          });

          const promosToUpdatePromises = promosToUpdate.map((p) => {
            return this.api.put(`/promo/voucher/${p.id}`, {
              active: p.active,
              code: p.code,
              metadata: {
                name: p.name,
                location_id: siteId,
                is_badgepay_promo: p.is_badgepay_promo || false,
              },
              app: p.app,
            });
          });
          const promosToCreatePromises = promosToCreate.map((p) =>
            this.api.post(`/promo/voucher`, {
              code: p.code,
              discount: {
                type: p.discountType,
                amount_off: p.discountType === 'AMOUNT' ? p.amount : undefined,
                percent_off: p.discountType === 'PERCENT' ? p.amount : undefined,
              },
              metadata: {
                name: p.name,
                location_id: siteId,
                is_mealplan_promo: p.is_mealplan_promo || false,
                is_badgepay_promo: p.is_badgepay_promo || false,
                is_declining_balance_promo: p.is_declining_balance_promo || false,
                is_voucher_promo: p.is_voucher_promo || false,
              },
              active: p.active,
              app: p.app,
            }),
          );

          promotions = (
            await Promise.all([
              ...promosToDeletePromises,
              ...promosToUpdatePromises,
              ...promosToCreatePromises,
            ])
          )
            .filter((p) => !!p.data.id)
            .map((p) => ({
              name: p.data.metadata.name,
              code: p.data.code,
              discountType: p.data.discount.type,
              amount: p.data.discount.percent_off || p.data.discount.amount_off,
              active: p.data.active,
              id: p.data.id,
              app: p.data.app,
              is_mealplan_promo: p.data.metadata?.is_mealplan_promo || false,
              is_badgepay_promo: p.data.metadata.is_badgepay_promo || false,
            }));
          promotions = [...promotions, ...promosWithNoUpdates];
        }
      } catch {
        error = true;
        infoNotSaved.push('Promotions');
      }

      if (!isEqual(this.backup.drop_off_locations, this.drop_off_locations)) {
        try {
          if (!siteId) {
            throw new Error('Site was not created.');
          }
          await this.updateDropOffLocations(siteId);

          // refresh the brand if dropoff locations were changed
          if (this.active_brand && this.active_brand.id) {
            await this.fetchBrand({
              id: this.active_brand.id,
              nocache: true,
            });
          }
        } catch (err) {
          console.error(err);
          error = true;
          infoNotSaved.push('Drop-off Locations');
        }
      }

      let polishedMealplan;
      const mealplanCopy = cloneDeep(this.mealplan);

      try {
        if (error) throw new Error('Error occurred, stop flow');
        polishedMealplan = this.polishMealplan(mealplanCopy, 'public');
        const freedompay = self.payment.freedompay || undefined;

        let badge_pay;
        let cashless;
        if (freedompay) {
          // badge pay system key should be stored in private config
          badge_pay = cloneDeep(this.badge_pay);
          freedompay.badge_pay_system_key = badge_pay?.badge_pay_system_key;
          delete badge_pay?.badge_pay_system_key;
          // can remove above once Badge pay OBJ is deprecated
          cashless = cloneDeep(this.cashless);
          if (cashless?.badge_pay_system_key) {
            freedompay.badge_pay_system_key = cashless?.badge_pay_system_key;
            delete cashless?.badge_pay_system_key;
          }
        }
        if (!isEmpty(cashless)) {
          badge_pay.id = ID('payment', 'fp', 'badgepay', this.site.id);
          badge_pay.name = cashless.name;
          badge_pay.type = cashless.type;
          badge_pay.webview = cashless.webview;
        }
        await this.postConfigPublic({
          id: siteId,
          config: {
            mealplan: polishedMealplan,
            promotions: havePromotionsChanged ? promotions : this.promotions,
            badge_pay,
            cashless,
          },
        });
      } catch (err) {
        if (!(err && err.response && err.response.data.code === 404)) {
          error = true;
          infoNotSaved.push('Mealplan');
          infoNotSaved.push('Promotions');
        }
      }

      if (
        !isEqual(
          this.backup.publicConfig.frictionless_supported,
          this.frictionless.frictionless_supported,
        )
      ) {
        try {
          const data = await this.getConfigPublic({ id: 'cognition' });
          let { groups } = data;

          if (this.frictionless.frictionless_supported) {
            // if frictionless is enabled, add site id to groups array
            groups.push(siteId);
          } else {
            // else, filter out site id from groups array
            groups = groups.filter((id) => id !== siteId);
          }

          await this.postConfigPublic({
            id: 'cognition',
            config: { groups },
          });
        } catch (err) {
          if (!(err && err.response && err.response.data.code === 404)) {
            error = true;
            infoNotSaved.push('Frictionless');
          }
        }
      }

      this.backup.publicConfig = {
        mealplan: cloneDeep(this.mealplan),
        promotions: cloneDeep(this.promotions),
        frictionless_supported: cloneDeep(this.frictionless.frictionless_supported),
        badge_pay: cloneDeep(this.badge_pay),
        cashless: cloneDeep(this.cashless),
      };

      try {
        if (error) throw new Error('Error occurred, stop flow');
        const privateConfig = {
          payment: {
            freedompay: self.payment.freedompay || undefined,
            refund: self.payment.refund,
          },
          keystore: {
            payment: self.keystore.payment || undefined,
          },
          loyalty: {
            provider_id: self.loyalty.provider_id || undefined,
            loyalty_emails:
              self.loyalty.loyalty_emails && self.loyalty.loyalty_emails.length
                ? self.loyalty.loyalty_emails
                : undefined,
            enabled: self.loyalty.enabled,
          },
          supports: {
            reorder: self.supports.reorder,
          },
          apex: {
            apex_integrated: self.apex.apex_integrated,
            apex_client_numb: self.apex.apex_client_numb,
          },
        };
        polishedMealplan = this.polishMealplan(mealplanCopy, 'private');
        if (polishedMealplan) {
          const isCbord =
            polishedMealplan.keystore &&
            (polishedMealplan.keystore.mealplan.type === MEALPLAN_TYPES.CBORD.toLowerCase() ||
              polishedMealplan.keystore.mealplan.type ===
                MEALPLAN_TYPES.CBORD_DIRECT.toLowerCase());
          if (isCbord) {
            privateConfig.keystore.mealplan = polishedMealplan.keystore.mealplan;
          } else {
            privateConfig.mealplan = polishedMealplan;
          }
        }
        await this.postConfigPrivate({
          id: siteId,
          config: privateConfig,
        });

        this.backup.privateConfig = {
          payment: cloneDeep(this.payment),
          keystore: cloneDeep(this.keystore),
          loyalty: cloneDeep(this.loyalty),
          supports: cloneDeep(this.supports),
          apex: cloneDeep(this.apex),
        };
      } catch (err) {
        if (!(err && err.response && err.response.data.code === 404)) {
          error = true;
          infoNotSaved.push('Payment');
          infoNotSaved.push('Loyalty');
        }
      }
      if (!isEqual(this.backup.linkedLocalMenuGroups, this.linkedLocalMenuGroups)) {
        // Site<>LMG links

        try {
          // Find objects in this.linkedLocalMenuGroups that are not in this.backup.linkedLocalMenuGroups and need to be saved
          let toSave = this.linkedLocalMenuGroups.filter((lmg) =>
            this.getObjectsNotInArray(lmg, this.backup.linkedLocalMenuGroups),
          );
          // Find objects in this.backup.linkedLocalMenuGroups that are not in this.linkedLocalMenuGroups and need to be deleted
          const toDelete = this.backup.linkedLocalMenuGroups.filter((lmg) =>
            this.getObjectsNotInArray(lmg, this.linkedLocalMenuGroups),
          );
          const promises = [];

          if (toSave.length) {
            if (this.isNewSite) {
              toSave = toSave.map((link) => {
                link.site_id = siteId;
                return link;
              });
            }
            promises.push(this.linkSiteToLocalMenuGroups(toSave));
          }
          if (toDelete.length) {
            promises.push(this.removeSiteToLocalMenuGroupsLink(toDelete));
          }
          await Promise.all(promises);
        } catch (err) {
          console.error(err);
        }
      }

      if (!error) {
        this.savedSuccessfully = true;
        this.$toast('Saved successfully');
        await this.goToSite(siteId);
      } else {
        this.$toast.error(`Unable to save ${infoNotSaved.join(', ')} information.`);
      }
    },
    cancel() {
      // restore backups
      this.$set(this, 'mealplan', cloneDeep(this.backup.publicConfig.mealplan));
      this.$set(this, 'loyalty', cloneDeep(this.backup.privateConfig.loyalty));
      this.$set(this, 'payment', cloneDeep(this.backup.privateConfig.payment));
      this.$set(this, 'keystore', cloneDeep(this.backup.privateConfig.keystore));
      this.$set(this, 'supports', cloneDeep(this.backup.privateConfig.supports));
      this.$set(this, 'site', cloneDeep(this.backup.siteDetails));
      this.$set(this, 'promotions', cloneDeep(this.backup.publicConfig.promotions));
      this.$set(this, 'badge_pay', cloneDeep(this.backup.publicConfig.badge_pay));
      this.$set(this, 'cashless', cloneDeep(this.backup.publicConfig.cashless));
      this.$set(this, 'isMultigroupSelected', !!this.site.app);
      this.$set(this, 'apex', cloneDeep(this.backup.privateConfig.apex));
      this.$set(this, 'drop_off_locations', cloneDeep(this.backup.drop_off_locations));
      this.$set(
        this.frictionless,
        'frictionless_supported',
        cloneDeep(this.backup.publicConfig.frictionless_supported),
      );
      this.$set(this, 'linkedLocalMenuGroups', cloneDeep(this.backup.linkedLocalMenuGroups));

      this.$refs.form.resetValidation();
      this.$toast('All your changes have been reset');
    },
    async fetchData() {
      let siteDetails;
      const siteId = this.$route.params.site_id;
      const infoNotRetrieved = [];
      let error = false;
      if (!this.isNewSite) {
        try {
          siteDetails = await this.getLocationGroup({ id: siteId, nocache: true }).catch(() =>
            this.getLocationGroup({ id: siteId }),
          );
        } catch (err) {
          siteDetails = {};
          console.error(err);
          error = true;
          infoNotRetrieved.push('Site Details');
        } finally {
          const location = siteDetails.locations && siteDetails.locations[0];
          const siteCoordinates = siteDetails.address && siteDetails.address.coordinates;
          const coordinates =
            siteCoordinates || (location && location.address && location.address.coordinates);

          this.site = {
            ...siteDetails,
            coordinates: { ...coordinates },
            app: (location && location.location_multigroup) || this.$route.params.app,
          };
          this.isMultigroupSelected = !!this.site.app;
          if (this.site.address.country.length > 2) {
            const start = this.site.address.country.toLowerCase()[0];
            if (start === 'c') {
              this.site.address.country = 'Canada';
            }
            if (start === 'u') {
              this.site.address.country = 'United States';
            }
          }

          if (this.site.address.country.length > 2) {
            const start = this.site.address.country.toLowerCase()[0];
            if (start === 'c') {
              this.site.address.country = 'CA';
            }
            if (start === 'u') {
              this.site.address.country = 'US';
            }
          }

          this.backup.siteDetails = cloneDeep(this.site);
        }
      }
      let delivery_destinations = [];
      if (siteId && this.isCDLLocation) {
        delivery_destinations = await this.getDeliveryDestinations({ id: siteId });
      }
      this.$set(this, 'drop_off_locations', delivery_destinations);
      this.backup.drop_off_locations = cloneDeep(delivery_destinations);
      const [publicConfig, privateConfig] = await Promise.all([
        this.getConfigPublic({ id: siteId }).catch((err) => {
          if (err && err.response && err.response.data.code !== 404) {
            console.error(err);
            error = true;
            infoNotRetrieved.push('Mealplan');
          }
          return {};
        }),

        this.getConfigPrivate({ id: siteId }).catch((err) => {
          if (err && err.response && err.response.data.code !== 404) {
            console.error(err);
            error = true;
            infoNotRetrieved.push('Payment');
            infoNotRetrieved.push('Loyalty');
          }
          return {};
        }),
      ]);
      let mealplan =
        (Array.isArray(publicConfig.mealplan) && publicConfig.mealplan) || this.mealplan;
      const mealplanFromPrivateConfig = privateConfig.mealplan || privateConfig;
      const isCbord =
        mealplanFromPrivateConfig &&
        mealplanFromPrivateConfig.keystore &&
        (mealplanFromPrivateConfig.keystore.mealplan.type === MEALPLAN_TYPES.CBORD.toLowerCase() ||
          mealplanFromPrivateConfig.keystore.mealplan.type ===
            MEALPLAN_TYPES.CBORD_DIRECT.toLowerCase());
      if (
        mealplanFromPrivateConfig &&
        Array.isArray(mealplanFromPrivateConfig) &&
        mealplanFromPrivateConfig.length > 0
      ) {
        mealplan = this.fuseMealplan(mealplan[0], mealplanFromPrivateConfig[0]);
      } else if (isCbord) {
        mealplan = this.fuseMealplan(mealplan[0], mealplanFromPrivateConfig);
      }
      this.mealplan = mealplan;
      const promotions = (Array.isArray(publicConfig.promotions) && publicConfig.promotions) || [];
      this.$set(this, 'promotions', promotions);
      const payment = privateConfig.payment || this.payment;
      if (payment?.freedompay?.badge_pay_system_key) {
        if (!isEmpty(publicConfig.badge_pay))
          this.badge_pay = {
            ...publicConfig.badge_pay,
            badge_pay_system_key: payment?.freedompay?.badge_pay_system_key,
          };
        if (!isEmpty(publicConfig.cashless))
          this.cashless = {
            ...publicConfig.cashless,
            badge_pay_system_key: payment?.freedompay?.badge_pay_system_key,
          };
      }
      this.payment = payment;
      this.keystore = privateConfig.keystore || this.keystore;

      this.backup.publicConfig = {
        mealplan: cloneDeep(this.mealplan),
        promotions: cloneDeep(this.promotions),
        badge_pay: cloneDeep(this.badge_pay),
        cashless: cloneDeep(this.cashless),
        frictionless_supported: this.frictionless.frictionless_supported,
      };
      this.apex = privateConfig.apex || this.apex;
      this.loyalty = privateConfig.loyalty || this.loyalty;
      this.supports = privateConfig.supports || this.supports;
      this.backup.privateConfig = {
        payment: cloneDeep(this.payment),
        keystore: cloneDeep(this.keystore),
        loyalty: cloneDeep(this.loyalty),
        supports: cloneDeep(this.supports),
        apex: cloneDeep(this.apex),
      };

      if (error) {
        this.$toast.error(`Unable to fetch ${infoNotRetrieved.join(', ')} information.`);
        throw new Error('Could not fetch data');
      }
    },
    async goToSite(id) {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: this.site.name,
        last: true,
        to: { name: 'site-info', params: { site_id: id, app: this.site.app } },
      });
      this.$router.push({
        name: 'site-info',
        params: {
          site_id: id,
          app: this.site.app,
        },
      });
      await this.$store.dispatch('sites/fetchSite', {
        id,
        multigroupId: this.site.app,
        nocache: true,
      });
    },
    /**
     * A pre-save hook for meal plan configs
     */
    polishMealplan(mealplan, configType) {
      const m = mealplan && mealplan[0];
      if (m) {
        if (m.type === MEALPLAN_TYPES.ATRIUM) {
          let { id } = m.public;
          const decoded_id = id && ID(id) && ID(id).id;
          if (this.isNewSite && this.site.id && !decoded_id) {
            id = ID('mealplan', 'atrium', 'mealplan', this.site.id);
          }
          if (configType === 'private') {
            /* tender configs have been moved to public config, cloning to private to maintain backward compatibility */
            const updated_private_config = cloneDeep(m.private);
            updated_private_config.config.tenders = cloneDeep(m.public.tenders);
            return [
              {
                ...updated_private_config,
                id,
                name: m.name,
                type: MEALPLAN_TYPES.ATRIUM,
              },
            ];
          }
          if (configType === 'public') {
            return [
              {
                id,
                name: m.name,
                tenders: m.public.tenders,
                type: MEALPLAN_TYPES.ATRIUM,
                note: m.note,
                valid_email_domains: m.valid_email_domains,
              },
            ];
          }
        }
        if (m.type === MEALPLAN_TYPES.CBORD || m.type === MEALPLAN_TYPES.CBORD_DIRECT) {
          if (configType === 'private') {
            const privateMealplan = m.private;
            privateMealplan.keystore.mealplan.name = m.name;
            privateMealplan.keystore.mealplan.type = m.type.toLowerCase();
            if (m.type === MEALPLAN_TYPES.CBORD_DIRECT) {
              privateMealplan.keystore.mealplan.auth_type = 1;
              privateMealplan.keystore.mealplan.auth_url = null;
            } else {
              privateMealplan.keystore.mealplan.auth_type = 2;
            }
            return privateMealplan;
          }
          if (configType === 'public') {
            const decoded = ID(this.site.id);
            decoded.service = 'mealplan';
            decoded.type = 'mealplan';
            decoded.provider = 'cbord';
            return [
              {
                id: ID(decoded),
                name: m.name,
                tenders: m.tenders,
                type: m.type,
                note: m.note,
                valid_email_domains: m.valid_email_domains,
                terminal_id: m.terminal_id,
              },
            ];
          }
        }
        if (
          this.isTypeBlackboard(m.type) ||
          this.isTypeItc(m.type) ||
          m.type === MEALPLAN_TYPES.HEARTLAND
        ) {
          let { id } = m;
          const decoded_id = id && ID(id) && ID(id).id;
          if (this.isNewSite && this.site.id && !decoded_id) {
            id = ID('mealplan', this.getMealplanProviderName(m.type), 'mealplan', this.site.id);
          }
          if (configType === 'private') {
            return [
              {
                ...m.private,
                id,
              },
            ];
          }
          if (configType === 'public') {
            return [
              {
                ...m,
                private: undefined,
                id,
              },
            ];
          }
        }
      }
      return mealplan;
    },
    fuseMealplan(m1, m2) {
      m2 = cloneDeep(m2);
      if (m1.type === MEALPLAN_TYPES.CBORD || m1.type === MEALPLAN_TYPES.CBORD_DIRECT) {
        return [
          {
            id: m1.id,
            name: m1.name,
            note: m1.note,
            tenders: m1.tenders,
            private: m2,
            type: m1.type,
            valid_email_domains: m1.valid_email_domains,
            // backwards compatibility with existing users with string terminal_ids
            terminal_id:
              typeof m1.terminal_id === 'string' ? m1.terminal_id.split() : m1.terminal_id,
          },
        ];
      }
      if (
        this.isTypeBlackboard(m1.type) ||
        this.isTypeItc(m1.type) ||
        m1.type === MEALPLAN_TYPES.HEARTLAND
      ) {
        return [
          {
            ...m1,
            private: { keystore: {}, ...m2 },
          },
        ];
      }

      if (m1.type !== MEALPLAN_TYPES.ATRIUM) return [m1];
      if (!m2.id) m2.id = m1.id;
      return [
        {
          id: m1.id,
          name: m1.name,
          note: m1.note,
          public: m1,
          private: m2,
          type: m1.type,
          valid_email_domains: m1.valid_email_domains,
          terminal_id: m2.config.terminal_id,
        },
      ];
    },
    getObjectsNotInArray(obj, array) {
      return !array.some(
        (arrayObj) =>
          arrayObj?.site_id === obj?.site_id &&
          arrayObj?.local_menu_group_id === obj?.local_menu_group_id,
      );
    },
    async goTo(page) {
      if (page === 'site-info') {
        await this.goToSite(this.site.id);
        return;
      }
      this.$router.push({
        name: page,
      });
    },
    validateForm() {
      this.isMultigroupSelected = !!(this.site && this.site.app);
      return (
        this.$refs.form.validate() &&
        this.$refs.paymentRef.$refs.form.validate() &&
        this.$refs.paymentRef.isEditing === false &&
        (this.isMultigroupSelected || !this.isCDLLocation())
      );
    },
    visibilityChanged(isVisible, entry) {
      this.$refs.toc.visibilityChanged(isVisible, entry);
    },
    intersect(intersected) {
      this.isHeaderVisible = intersected;
    },
    editKeystore(keystoreValues) {
      this.keystore = keystoreValues;
    },
    editPayment(paymentValues) {
      this.payment = paymentValues;
    },
  },
};
</script>

<style></style>
