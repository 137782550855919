<template>
  <v-flex xs12 class="mb-5">
    <v-card>
      <v-card-title primary-title>
        <div class="H5-Primary-Left">Linked Local Menu Groups</div>
      </v-card-title>
      <div class="v-card-content">
        <v-flex xs6 class="px-0">
          <v-text-field
            v-model="searchInput"
            class="lmg-search"
            outline
            clearable
            :loading="isLoading"
            placeholder="Search Local Menu Groups"
            :disabled="isDisabled"
          />
          <v-layout row style="padding-left: 12px; padding-right: 12px">
            <div style="overflow: hidden;  width: 100%">
              <v-list class="lmg-list-panel" style="border: solid 1px #757575;">
                <template v-if="!isLoading">
                  <v-list-tile
                    v-for="(localMenuGroup, index) in items"
                    :key="localMenuGroup.local_menu_group_id + index"
                  >
                    <v-list-tile-action>
                      <v-checkbox
                        v-model="linkedLocalMenuGroupsProxy"
                        :value="localMenuGroup"
                        :label="localMenuGroup.local_menu_group_name"
                        :disabled="isDisabled"
                      />
                    </v-list-tile-action>
                  </v-list-tile>
                </template>
                <div v-else>
                  <v-progress-linear color="blue" indeterminate height="7"></v-progress-linear>
                </div>
              </v-list>
            </div>
          </v-layout>
        </v-flex>
      </div>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import { UserRoles } from '@/constants';

export default {
  props: ['linkedLocalMenuGroups', 'linkedLocalMenuGroupsBackup'],

  data: () => ({
    isLoading: false,
    searchInput: '',
    searchResults: [],
    list: [],
  }),
  computed: {
    ...mapGetters('adminPanel', ['userHasAnyRole']),
    linkedLocalMenuGroupsProxy: {
      get() {
        return this.linkedLocalMenuGroups;
      },
      set(value) {
        this.$emit('update:linkedLocalMenuGroups', value);
      },
    },
    items() {
      if (this.searchInput?.length > 3) {
        const siteId = this.$route?.params?.site_id;
        return this.searchResults.map((result) => {
          return {
            site_id: siteId,
            local_menu_group_id: result?.id,
            local_menu_group_name: result?.name,
          };
        });
      }
      return this.list;
    },
    isDisabled() {
      const hasHighPermissionRole = this.userHasAnyRole([UserRoles.admin, UserRoles.sys_admin]);
      return !hasHighPermissionRole;
    },
  },

  methods: {
    ...mapActions('centricOSMenu', ['getMenuLocalMenuGroups', 'getSitesLinkedLocalMenuGroups']),
  },
  watch: {
    linkedLocalMenuGroups: {
      // Retain all values that have been selected during this session.
      handler(newVal) {
        const combinedUniqueLocalMenuGroups = [...this.list, ...newVal].reduce((acc, current) => {
          if (!acc.find((item) => item.local_menu_group_id === current.local_menu_group_id)) {
            acc.push(current);
          }
          return acc;
        }, []);
        this.list = combinedUniqueLocalMenuGroups;
      },
    },
    searchInput: debounce(async function searchInput(val = '') {
      if (val === null) return;
      // LMGS have already been requested
      if (this.isLoading || val.length < 3) return;
      // skip search when input is selected from last search
      if (this.searchResults.find((m) => m?.local_menu_group_name === val)) return;

      this.isLoading = true;
      const params = {
        filter: { name: { $contains: val } },
      };
      this.searchResults = await this.getMenuLocalMenuGroups(params);
      this.isLoading = false;
    }, 500),
  },
  async mounted() {
    const siteId = this.$route?.params?.site_id;
    if (!siteId) return; // New site
    this.isLoading = true;
    const linkedLMGs = await this.getSitesLinkedLocalMenuGroups(siteId);
    this.isLoading = false;
    this.$emit('update:linkedLocalMenuGroups', linkedLMGs);
    this.$emit('update:linkedLocalMenuGroupsBackup', linkedLMGs);
  },
};
</script>

<style>
.lmg-list-panel {
  height: 20vh;
  overflow: scroll;
  margin: 0;
}
.lmg-search > .v-input__control > .v-text-field__details {
  display: none;
}
.lmg-search > .v-input__control > .v-input__slot {
  margin: 0;
}

.lmg-search > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  margin: auto 0;
}
</style>
