import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [
          _vm._v("Delivery Drop-off Locations")
        ])
      ]),
      _c(
        "div",
        { staticClass: "v-card-content" },
        [
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs6: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "Search Locations",
                      "prepend-inner-icon": "mdi-magnify",
                      clearable: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VLayout,
            { staticStyle: { "margin-bottom": "12px" } },
            [
              _c(
                VFlex,
                [
                  _c(
                    VDataTable,
                    {
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.availableDropOffLocations,
                        search: _vm.filter,
                        pagination: _vm.pagination
                      },
                      on: {
                        "update:pagination": function($event) {
                          _vm.pagination = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "headers",
                          fn: function(props) {
                            return [
                              _c(
                                "tr",
                                [
                                  _vm._l(props.headers, function(header) {
                                    return _c(
                                      "th",
                                      {
                                        key: header.name,
                                        class: [
                                          "column sortable",
                                          _vm.pagination.descending
                                            ? "desc"
                                            : "asc",
                                          header.value === _vm.pagination.sortBy
                                            ? "active"
                                            : ""
                                        ],
                                        staticStyle: { "text-align": "left" },
                                        attrs: {
                                          scope: "col",
                                          width: header.width
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.changeSort(header.value)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(header.text) +
                                            "\n                "
                                        ),
                                        _c(VIcon, { attrs: { small: "" } }, [
                                          _vm._v("mdi-arrow-up")
                                        ])
                                      ],
                                      1
                                    )
                                  }),
                                  _c("th", {
                                    staticStyle: { "min-width": "135px" },
                                    attrs: { scope: "col", width: "20%" }
                                  })
                                ],
                                2
                              )
                            ]
                          }
                        },
                        {
                          key: "items",
                          fn: function(props) {
                            return [
                              _c(
                                "tr",
                                {
                                  staticStyle: {
                                    height: "50px !important",
                                    width: "950px !important"
                                  },
                                  on: {
                                    mouseover: function($event) {
                                      _vm.showActions = props.item.id
                                    },
                                    mouseleave: function($event) {
                                      _vm.showActions = null
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: { "white-space": "nowrap" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(props.item.name) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c("td", [
                                    props.item.foodlocker
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "foodlocker Body-1-Selected-On-Secondary-High-Emphasis-Left"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Foodlocker\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isAdmin,
                                              expression: "isAdmin"
                                            }
                                          ]
                                        },
                                        [
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.showActions ===
                                                                      props.item
                                                                        .id,
                                                                    expression:
                                                                      "showActions === props.item.id"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "ma-0",
                                                                attrs: {
                                                                  flat: "",
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.edit(
                                                                      props.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-pencil"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Edit Location")
                                              ])
                                            ]
                                          ),
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.showActions ===
                                                                      props.item
                                                                        .id,
                                                                    expression:
                                                                      "showActions === props.item.id"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "ma-0",
                                                                attrs: {
                                                                  flat: "",
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.duplicate(
                                                                      props.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-content-copy"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Duplicate Location")
                                              ])
                                            ]
                                          ),
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.showActions ===
                                                                      props.item
                                                                        .id,
                                                                    expression:
                                                                      "showActions === props.item.id"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "ma-0",
                                                                attrs: {
                                                                  flat: "",
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.remove(
                                                                      props.item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-delete"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Remove Location")
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("template", { slot: "no-data" }, [
                        _c(
                          "td",
                          {
                            staticClass: "text-xs-center",
                            attrs: { colspan: "3" }
                          },
                          [_vm._v("No locations found")]
                        )
                      ]),
                      _c("template", { slot: "no-results" }, [
                        _c(
                          "td",
                          {
                            staticClass: "text-xs-center",
                            attrs: { colspan: "3" }
                          },
                          [_vm._v("No locations found")]
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "", disabled: !_vm.isDisabled },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  { staticStyle: { display: "inline-block" } },
                                  on
                                ),
                                [
                                  _c(
                                    VBtn,
                                    _vm._b(
                                      {
                                        staticStyle: { margin: "0" },
                                        attrs: {
                                          flat: "",
                                          color: "blue",
                                          disabled: _vm.isDisabled
                                        },
                                        on: { click: _vm.addDropOffLocation }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    [
                                      _vm._v(
                                        "\n                CREATE DROP-OFF LOCATION\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v("This feature is not available for this site.")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { width: "600" },
              model: {
                value: _vm.dialogRemoveConfirm,
                callback: function($$v) {
                  _vm.dialogRemoveConfirm = $$v
                },
                expression: "dialogRemoveConfirm"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    {
                      staticClass: "H4-Primary-Left pt-5",
                      staticStyle: {
                        "padding-right": "40px",
                        "padding-left": "40px"
                      }
                    },
                    [_vm._v("\n          Remove Location?\n        ")]
                  ),
                  _c(VFlex, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "Body-1-Black-High-Emphasis-Left p-remove-spacing"
                      },
                      [
                        _vm._v(
                          "\n            Are you sure you want to remove this drop-off location? It will take the location off\n            the page and the timeslot associated with it will be removed at the same time.\n          "
                        )
                      ]
                    )
                  ]),
                  _c(
                    VCardActions,
                    { staticStyle: { padding: "0 40px 40px" } },
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", flat: "" },
                          on: { click: _vm.closeRemoveModal }
                        },
                        [_vm._v("CANCEL")]
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", flat: "" },
                          on: { click: _vm.confirmRemove }
                        },
                        [_vm._v("REMOVE")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { width: "640" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    {
                      staticClass: "H4-Primary-Left pt-5",
                      staticStyle: {
                        "padding-right": "40px",
                        "padding-left": "40px"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.dialogFormTitle) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    VForm,
                    { ref: "form", staticStyle: { padding: "8px 40px 32px" } },
                    [
                      _c(
                        VFlex,
                        { staticClass: "mb-3" },
                        [
                          _c(VTextField, {
                            attrs: {
                              label: "Location Name",
                              maxlength: "80",
                              hint:
                                "80 characters max, this name will appear on the consumer apps.",
                              rules: _vm.nameRules
                            },
                            model: {
                              value: _vm.dropOffLocationForm.name,
                              callback: function($$v) {
                                _vm.$set(_vm.dropOffLocationForm, "name", $$v)
                              },
                              expression: "dropOffLocationForm.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VFlex,
                        { staticClass: "mb-3" },
                        [
                          _c("location-autocomplete", {
                            attrs: { rules: _vm.addressRules },
                            on: { autocomplete: _vm.setPlace },
                            model: {
                              value: _vm.dropOffLocationForm.address,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.dropOffLocationForm,
                                  "address",
                                  $$v
                                )
                              },
                              expression: "dropOffLocationForm.address"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        { staticClass: "mb-3" },
                        [
                          _c(
                            VFlex,
                            { staticClass: "mr-4", attrs: { xs6: "" } },
                            [
                              _c(VAutocomplete, {
                                ref: "countries",
                                attrs: {
                                  label: "Country",
                                  placeholder: "Choose Country",
                                  items: _vm.countries,
                                  rules: _vm.countryRules
                                },
                                on: {
                                  blur: function($event) {
                                    return _vm.blurSelect("countries")
                                  }
                                },
                                model: {
                                  value: _vm.dropOffLocationForm.country,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dropOffLocationForm,
                                      "country",
                                      $$v
                                    )
                                  },
                                  expression: "dropOffLocationForm.country"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { attrs: { xs6: "" } },
                            [
                              _c(VTextField, {
                                attrs: { label: "City", rules: _vm.cityRules },
                                model: {
                                  value: _vm.dropOffLocationForm.city,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dropOffLocationForm,
                                      "city",
                                      $$v
                                    )
                                  },
                                  expression: "dropOffLocationForm.city"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        { staticClass: "mb-3" },
                        [
                          _c(
                            VFlex,
                            { staticClass: "mr-4", attrs: { xs6: "" } },
                            [
                              _c(VAutocomplete, {
                                ref: "states",
                                attrs: {
                                  placeholder: _vm.statePlaceholder,
                                  label: _vm.stateLabel,
                                  items: _vm.states,
                                  rules: _vm.stateRules
                                },
                                on: {
                                  blur: function($event) {
                                    return _vm.blurSelect("states")
                                  }
                                },
                                model: {
                                  value: _vm.dropOffLocationForm.state,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dropOffLocationForm,
                                      "state",
                                      $$v
                                    )
                                  },
                                  expression: "dropOffLocationForm.state"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { attrs: { xs6: "" } },
                            [
                              _c(VTextField, {
                                attrs: {
                                  maxlength: "9",
                                  label: _vm.codeLabel,
                                  rules: _vm.addressCodeRules
                                },
                                model: {
                                  value: _vm.dropOffLocationForm.zip,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dropOffLocationForm,
                                      "zip",
                                      $$v
                                    )
                                  },
                                  expression: "dropOffLocationForm.zip"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(VFlex, { staticClass: "mt-4 mb-4" }, [
                        _c(
                          "div",
                          { staticStyle: { position: "relative" } },
                          [
                            _c(VTextarea, {
                              attrs: {
                                label: "Location Information (optional)",
                                name: "dropOffLocationInformation",
                                rows: "2",
                                maxlength: _vm.textareaCharLimit,
                                "hide-details": "",
                                outline: "",
                                "no-resize": ""
                              },
                              model: {
                                value: _vm.dropOffLocationForm.information,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.dropOffLocationForm,
                                    "information",
                                    $$v
                                  )
                                },
                                expression: "dropOffLocationForm.information"
                              }
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  bottom: "8px",
                                  right: "12px"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.textAreaCharLength) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "mt-2",
                            staticStyle: {
                              color: "rgba(0, 0, 0, 0.6)",
                              "font-size": "13.5467px",
                              "line-height": "16px",
                              "letter-spacing": "0.4px"
                            }
                          },
                          [
                            _vm._v(
                              "\n              This message will appear at checkout and in the order confirmation notification.\n            "
                            )
                          ]
                        )
                      ]),
                      _c(
                        VFlex,
                        { staticClass: "mt-2", attrs: { xs7: "" } },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "mb-2",
                              staticStyle: {
                                color: "rgba(0, 0, 0, 0.6)",
                                "font-size": "18px",
                                "line-height": "24px",
                                "letter-spacing": "0.15px"
                              }
                            },
                            [
                              _vm._v(
                                "\n              Is this an APEX Foodlocker?\n            "
                              )
                            ]
                          ),
                          _c("toggle", {
                            staticStyle: { margin: "0 !important" },
                            attrs: { position: "'start'" },
                            model: {
                              value: _vm.dropOffLocationForm.foodlocker,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.dropOffLocationForm,
                                  "foodlocker",
                                  $$v
                                )
                              },
                              expression: "dropOffLocationForm.foodlocker"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    { staticStyle: { padding: "0 40px 40px" } },
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", flat: "" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("cancel")]
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.saveChanges }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.dropOffLocationForm.id
                                  ? "Save Changes"
                                  : "Create Location"
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }