import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [
          _vm._v("OneMarket Integration")
        ])
      ]),
      _c(
        VLayout,
        {
          attrs: {
            column: "",
            "justify-center": "",
            "align-center": "",
            "fill-height": ""
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages"
            },
            [_vm._v("\n      Allow OneMarket Menu Import?\n    ")]
          ),
          _c("toggle", {
            attrs: {
              onClickHandler: _vm.toggleOneMarket,
              disabled: _vm.isSectionDisabled
            },
            model: {
              value: _vm.onemarketProps.partner_menu_import_enabled,
              callback: function($$v) {
                _vm.$set(_vm.onemarketProps, "partner_menu_import_enabled", $$v)
              },
              expression: "onemarketProps.partner_menu_import_enabled"
            }
          }),
          _vm.onemarketProps.partner_menu_import_enabled
            ? _c(
                "div",
                { staticStyle: { margin: "0px auto 50px auto" } },
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          label: "Account Operation ID",
                          type: "number"
                        },
                        on: { input: _vm.inputAccountOperationID },
                        model: {
                          value: _vm.onemarketProps.account_operation_id,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.onemarketProps,
                              "account_operation_id",
                              $$v
                            )
                          },
                          expression: "onemarketProps.account_operation_id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }