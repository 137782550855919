<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Badge Pay</div>
      <v-spacer />
      <v-btn
        flat
        color="secondary"
        :disabled="isSectionDisabled"
        @click="showRemoveDialog = true"
        v-if="value && selectedProviderType"
      >
        <v-icon class="mr-1" left>mdi-delete-outline</v-icon>remove
      </v-btn>
    </v-card-title>
    <div class="v-card-content">
      <v-layout row wrap>
        <v-flex xs8>
          <v-select
            label="Plan Type"
            placeholder="Choose a Plan"
            :rules="[...validateFPConfig]"
            :items="providerTypes"
            :value="selectedProviderType"
            :disabled="isSectionDisabled"
            @input="selectProvider"
          ></v-select>
        </v-flex>
        <v-flex x4 />
      </v-layout>
      <v-layout row wrap v-if="selectedProviderType">
        <v-flex xs8>
          <v-text-field
            label="Plan Name"
            :value="value.name"
            :rules="requiredField"
            :disabled="isSectionDisabled"
            @input="value.name = safeTrim($event)"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            label="Webview URL"
            :value="value.webview"
            :rules="[...requiredField, ...isURL]"
            :disabled="isSectionDisabled"
            @input="value.webview = safeTrim($event)"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            label="System Key"
            ref="system_key"
            :value="value.badge_pay_system_key"
            :rules="requiredField"
            :disabled="isSectionDisabled"
            @input="value.badge_pay_system_key = safeTrim($event)"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-select
            label="Tax Exempt"
            placeholder="Select Tax Exemption"
            :rules="requiredFieldTaxExempt"
            :items="taxExemptionOptions"
            :value="getTaxExemption"
            :disabled="isSectionDisabled"
            @input="setTaxExemption"
          ></v-select>
        </v-flex>
        <div class="add-tender-btn">
          <v-btn flat color="primary" @click="createCashlessTender">
            <v-icon>mdi-plus</v-icon> <span>add another tender type</span>
          </v-btn>
        </div>
        <v-flex xs4 />
      </v-layout>
    </div>
    <remove-dialog
      :title="'Remove Badge Pay'"
      :descriptionLines="[
        'Removing cannot be undone.',
        'To continue with removal please enter REMOVE in the field below and continue.',
      ]"
      :onConfirmHandler="remove"
      v-model="showRemoveDialog"
      :disabled="isSectionDisabled"
    />
  </v-card>
</template>

<script>
import ID from '@compassdigital/id';
import removeDialog from '@/components/removeDialog';
import { safeTrim } from '@/helpers';
import rules from '@/rules';

export default {
  props: {
    value: {
      type: Object,
    },
    cashless: {
      type: Object,
    },
    siteId: {
      type: String,
    },
    payment: {
      type: Object,
    },
    isSectionDisabled: {
      type: Boolean,
      default: false,
    },
    formRef: {},
  },
  components: {
    removeDialog,
  },
  data: () => ({
    TAX_EXEMPT: [
      {
        text: 'Yes',
        value: true,
      },
      {
        text: 'No',
        value: false,
      },
    ],
    providerTypes: [{ text: 'FreedomPay', value: 'freedompay' }],
    taxExemptionOptions: [
      {
        text: 'Yes',
        value: true,
      },
      {
        text: 'No',
        value: false,
      },
    ],
    requiredField: [rules.required('This is a required Field')],
    requiredFieldTaxExempt: [rules.required('This is a required Field', [true, false])],
    isURL: [rules.url('Valid Webview URL is required')],
    showRemoveDialog: false,
  }),
  computed: {
    selectedProviderType() {
      return this.value?.type;
    },
    getTaxExemption() {
      return this.value?.tax_exempt;
    },
  },
  watch: {
    value(v) {
      this.$emit('input', v);
    },
    // eslint-disable-next-line func-names
    'payment.freedompay.freedompay_store_id': function() {
      this.formRef.validate();
    },
    // eslint-disable-next-line func-names
    'payment.freedompay.freedompay_terminal_id': function() {
      this.formRef.validate();
    },
    siteId() {
      if (this.value.type) {
        this.value.id = ID('payment', 'fp', 'badgepay', this.siteId);
        this.$emit('input', this.value);
      }
    },
  },
  methods: {
    createCashlessTender() {
      const cashlessTender = {
        tender_type: 'Badge Pay',
        name: '',
        tax_exempt: this.value.tax_exempt,
      };
      const badgePayValues = (({ id, tax_exempt, ...o }) => o)(this.value);
      const newCashlessObj = {
        ...badgePayValues,
        id: ID('payment', 'fp', 'cashless', this.siteId),
        tenders: [cashlessTender],
      };
      this.$emit('update:cashless', newCashlessObj);
    },
    selectProvider(provider) {
      if (provider === 'freedompay' && this.value?.type !== 'freedompay') {
        this.$emit('input', {
          id: ID('payment', 'fp', 'badgepay', this.siteId),
          type: provider,
          name: '',
          webview: '',
          badge_pay_system_key: '',
          tax_exempt: '',
        });
      }
    },
    setTaxExemption(tax_exempt) {
      this.value.tax_exempt = tax_exempt;
      this.$emit('input', this.value);
    },
    remove() {
      this.$emit('input', undefined);
    },
    validateFPConfig() {
      return (
        this.value?.type !== 'freedompay' ||
        Boolean(
          this.payment?.freedompay?.freedompay_store_id &&
            this.payment?.freedompay?.freedompay_terminal_id,
        ) ||
        'Error: Complete Payment Information to add Badge Pay'
      );
    },
    safeTrim,
  },
};
</script>

<style scoped>
.add-tender-btn {
  margin: 28px 0 0 20px;
  padding-bottom: 48px;
}
.add-tender-btn ::v-deep .v-btn {
  padding: 0;
}
.add-tender-btn ::v-deep span {
  margin-left: 24px;
}
</style>
