import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [
          _vm._v("APEX Foodlocker Configuration")
        ])
      ]),
      !_vm.apex.apex_integrated
        ? _c(
            "div",
            [
              _c(
                VLayout,
                {
                  attrs: {
                    column: "",
                    "justify-center": "",
                    "align-center": "",
                    "fill-height": ""
                  }
                },
                [
                  _c(VImg, {
                    staticClass: "apex-image",
                    attrs: {
                      src: require("@/assets/apex-config.svg"),
                      height: "174px",
                      width: "187px"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "Body-1-Black-High-Emphasis-Center apex-Description-Messages"
                    },
                    [
                      _vm._v(
                        "\n        Food Locker is not enabled for this site\n      "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "Subtitle-1-Black-Medium-Emphasis-Center apex-Description-Messages"
                    },
                    [
                      _vm._v(
                        "\n        Would you like to enable Food locker integration with APEX?\n      "
                      )
                    ]
                  ),
                  _c("toggle", {
                    model: {
                      value: _vm.apex.apex_integrated,
                      callback: function($$v) {
                        _vm.$set(_vm.apex, "apex_integrated", $$v)
                      },
                      expression: "apex.apex_integrated"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "apex-Content", staticStyle: { padding: "24px" } },
            [
              _c(
                VLayout,
                [
                  _c(
                    VFlex,
                    [
                      _c(VTextField, {
                        staticClass:
                          "Text-Field-Filled-Trailing-Icon-Pressed mb-3",
                        attrs: {
                          label: "Apex Client ID",
                          rules: _vm.apex_client_rules
                        },
                        model: {
                          value: _vm.apex.apex_client_numb,
                          callback: function($$v) {
                            _vm.$set(_vm.apex, "apex_client_numb", $$v)
                          },
                          expression: "apex.apex_client_numb"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    [
                      _c(
                        VLayout,
                        { attrs: { "justify-end": "" } },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "Remove-Button mt-4 mr-3",
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.showRemoveDialog = true
                                }
                              }
                            },
                            [
                              _c(
                                VIcon,
                                { staticClass: "mr-1", attrs: { left: "" } },
                                [_vm._v("mdi-delete-outline")]
                              ),
                              _vm._v("REMOVE\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c("remove-dialog", {
        attrs: {
          title: "Remove Food Locker Integration with APEX ",
          descriptionLines: [
            "If you remove this integration then customers won’t be able to pick up orders from any food locker at this site.",
            "To continue with deletion please enter REMOVE in the field below and continue"
          ],
          onConfirmHandler: _vm.removeApex
        },
        model: {
          value: _vm.showRemoveDialog,
          callback: function($$v) {
            _vm.showRemoveDialog = $$v
          },
          expression: "showRemoveDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }