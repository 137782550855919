<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">OneMarket Integration</div>
    </v-card-title>
    <v-layout column justify-center align-center fill-height>
      <div class="Body-1-Black-High-Emphasis-Center Loyalty-Description-Messages">
        Allow OneMarket Menu Import?
      </div>
      <toggle
        v-model="onemarketProps.partner_menu_import_enabled"
        :onClickHandler="toggleOneMarket"
        :disabled="isSectionDisabled"
      />
      <div v-if="onemarketProps.partner_menu_import_enabled" style=" margin: 0px auto 50px auto">
        <v-flex xs12>
          <v-text-field
            label="Account Operation ID"
            v-model="onemarketProps.account_operation_id"
            @input="inputAccountOperationID"
            type="number"
          />
        </v-flex>
      </div>
    </v-layout>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    withDialog: { type: Boolean, default: true },
    onemarketProps: { type: Object, required: true, default: () => {} },
    isSectionDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showDialog: false,
    menuImportLoader: false,
  }),
  computed: {
    ...mapState('sites', ['active_site']),
    onemarketState: {
      get() {
        return this.onemarketProps;
      },
      set(value) {
        return this.$emit('update:onemarketProps', value);
      },
    },
  },

  methods: {
    async inputAccountOperationID(value) {
      const onemarketProps = { ...this.onemarketState, account_operation_id: value };
      this.$emit('update:onemarketProps', onemarketProps);
    },
    async toggleOneMarket(enabled) {
      if (enabled) {
        const onemarketProps = { ...this.onemarketState, partner_menu_import_enabled: enabled };
        this.$emit('update:onemarketProps', onemarketProps);
      } else {
        this.showDialog = false;
        const input = await this.$confirm({
          title: 'Warning',
          message: 'Are you sure want to remove the Onemarket option?',
          buttonTrueText: 'OK',
          buttonFalseText: 'CANCEL',
        });
        if (input) {
          const onemarketProps = {
            ...this.onemarketState,
            partner_menu_import_enabled: false,
          };
          this.$emit('update:onemarketProps', onemarketProps);
        } else {
          this.onemarketState = { ...this.onemarketState, partner_menu_import_enabled: !enabled };
        }
      }
    },
  },
};
</script>
