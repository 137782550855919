<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">APEX Foodlocker Configuration</div>
    </v-card-title>
    <div v-if="!apex.apex_integrated">
      <v-layout column justify-center align-center fill-height>
        <v-img
          :src="require('@/assets/apex-config.svg')"
          height="174px"
          width="187px"
          class="apex-image"
        />
        <div class="Body-1-Black-High-Emphasis-Center apex-Description-Messages">
          Food Locker is not enabled for this site
        </div>
        <div class="Subtitle-1-Black-Medium-Emphasis-Center apex-Description-Messages">
          Would you like to enable Food locker integration with APEX?
        </div>
        <toggle v-model="apex.apex_integrated" />
      </v-layout>
    </div>
    <div class="apex-Content" style="padding: 24px;" v-else>
      <v-layout>
        <v-flex>
          <v-text-field
            label="Apex Client ID"
            v-model="apex.apex_client_numb"
            class="Text-Field-Filled-Trailing-Icon-Pressed mb-3"
            :rules="apex_client_rules"
          />
        </v-flex>
        <v-flex>
          <v-layout justify-end>
            <v-btn
              color="primary"
              class="Remove-Button mt-4 mr-3"
              v-on:click="showRemoveDialog = true"
            >
              <v-icon class="mr-1" left>mdi-delete-outline</v-icon>REMOVE
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
    <remove-dialog
      :title="'Remove Food Locker Integration with APEX '"
      :descriptionLines="[
        'If you remove this integration then customers won’t be able to pick up orders from any food locker at this site.',
        'To continue with deletion please enter REMOVE in the field below and continue',
      ]"
      :onConfirmHandler="removeApex"
      v-model="showRemoveDialog"
    />
  </v-card>
</template>

<script>
import removeDialog from '@/components/removeDialog';
import cloneDeep from 'lodash/cloneDeep';
import rules from '@/rules';

export default {
  components: {
    removeDialog,
  },
  props: {
    apex: {},
  },

  data: () => ({
    showRemoveDialog: false,
    apex_client_rules: [rules.required('Apex client number is a required field')],
  }),
  methods: {
    removeApex() {
      const clone_apex = cloneDeep(this.apex);
      clone_apex.apex_integrated = false;
      this.$emit('update:apex', clone_apex);
    },
  },
};
</script>

<style scoped>
.apex-Content {
  padding-left: 14px;
  padding-right: 14px;
  overflow: hidden;
}
.apex-Description-Messages {
  padding-top: 14px;
}
.apex-image {
  margin-top: 30px;
}
</style>
