<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Location Details</div>
    </v-card-title>
    <div v-if="timezone" class="timezone">Timezone: {{ timezone }}</div>
    <div class="v-card-content">
      <v-layout>
        <v-flex xs12 md7>
          <v-layout class="wrap">
            <v-flex xs12>
              <v-text-field
                v-model="value.name"
                label="Location Name"
                :rules="nameRules"
                :disabled="isDH"
              />
            </v-flex>
            <v-flex xs12>
              <location-autocomplete
                v-model="value.address.address"
                :rules="addressRules"
                :disabled="isDH"
                @autocomplete="setPlace"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md5 style="padding-left: 35px;">
          <image-uploader
            :imageUrl.sync="logoImageProxy"
            ref="logoImage"
            :width="324"
            :height="143"
            v-if="!isDH"
          />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs7 v-if="!isDH">
          <v-select :items="sectorNames" label="Sector" v-model="sectorName"></v-select>
        </v-flex>
        <v-flex xs5 />
      </v-layout>
      <v-layout>
        <v-flex xs7>
          <v-select
            :items="countries"
            label="Country"
            placeholder="Canada"
            v-model="value.address.country"
            :rules="countryRules"
            :disabled="isDH"
          ></v-select>
        </v-flex>
        <v-flex xs5>
          <v-select
            class="ml-4"
            :items="states"
            label="State"
            placeholder="Choose State"
            v-model="value.address.state"
            :rules="stateRules"
            :disabled="isDH || states.length === 0"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs7>
          <v-text-field
            v-model="value.address.city"
            label="City"
            :rules="cityRules"
            :disabled="isDH"
          />
        </v-flex>
        <v-flex xs5>
          <v-text-field
            class="ml-4"
            v-model="value.address.zip"
            :label="codeLabel"
            :rules="addressCodeRules"
            :disabled="isDH"
          />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs7 class>
          <v-text-field
            v-model="latLong"
            label="Latitude, Longitude"
            :rules="latlongRules"
            :disabled="isDH"
          />
        </v-flex>
      </v-layout>
      <v-flex xs6 />
      <div v-if="!isDH">
        <div class="my-3">
          <div class="H6-Selected-On-Surface-High-Emphasis-Left">On Site Product</div>
          <v-text-field
            class="App-Not-Selected-Error"
            :rules="appSelectionRules"
            :value="value.app"
          />
        </div>
        <div>
          <v-flex xs12>
            <v-layout justify-start row wrap>
              <div v-for="(app, index) in multigroups" v-bind:key="index" class="mb-3 mr-3">
                <div
                  class="App-Container"
                  v-on:click="select(app.id)"
                  v-bind:class="{
                    'Selected-App': app.id === value.app,
                    'disabled-app': app.id !== value.app && value.app === $route.params.app,
                  }"
                >
                  <div class="App-Image-Box">
                    <div v-if="app.id === value.app" class="Selected-Icon-Box">
                      <v-icon class="Selected-Icon">mdi-checkbox-marked-circle</v-icon>
                    </div>
                    <div class="App-Image">
                      <v-img
                        :src="require('@/assets/app-logo.svg')"
                        width="158px"
                        height="158px"
                      ></v-img>
                    </div>
                  </div>
                  <div
                    class="py-2 pl-2"
                    style="overflow: hidden; text-overflow: ellipsis;"
                    v-bind:class="{
                      'Body-1-Primary-Left': app.id === value.app,
                      'Body-1-Selected-On-Surface-Medium': app.id !== value.app,
                    }"
                  >
                    {{ app.name }}
                  </div>
                </div>
              </div>
            </v-layout>
          </v-flex>
        </div>
        <v-flex xs5 v-if="isNewSite || isExternalPartner">
          <div class="Subtitle-1-Black-Medium-Emphasis-Left">Is this a non-CDL site?</div>
          <toggle
            v-model="isExternalPartner"
            :disabled="!value.app || !isNewSite"
            :position="'start'"
          />
          <v-text-field
            v-if="isExternalPartner"
            v-model="value.meta.external_partner"
            label="Partner name (defaults to multigroup name lowercase) "
            :rules="externalPartnerRules(value.meta.external_partner)"
            :disabled="!isNewSite"
          />
        </v-flex>
      </div>
    </div>
  </v-card>
</template>

<script>
import ID from '@compassdigital/id';

import { mapGetters, mapState } from 'vuex';
import provinces from '@/assets/provinces.json';
import rules from '@/rules';
import locationAutocomplete from '@/components/location-autocomplete';
import imageUploader from '@/components/imageUploader';

export default {
  components: {
    locationAutocomplete,
    imageUploader,
  },
  props: ['value', 'isNewSite'],
  data: () => ({
    countries: [
      {
        text: 'Canada',
        value: 'CA',
      },
      {
        text: 'United States',
        value: 'US',
      },
    ],
    applications: [
      { text: 'Thrive', value: 'thrive' },
      { text: 'Boost', value: 'boost' },
      { text: 'Nourish', value: 'nourish' },
      { text: 'Roger Eats', value: 'rogereats' },
      { text: 'J&J Kitchen', value: 'jjkitchen' },
    ],
    nameRules: [rules.required('Location Name is Required')],
    externalPartnerRules: (name) => [
      () => (name && name.toLowerCase() === name) || 'Name should be in lower case',
    ],
    addressRules: [rules.required('Address is Required')],
    countryRules: [rules.required('Country is Required')],
    stateRules: [rules.required('State is Required')],
    cityRules: [rules.required('City is Required')],
    latlongRules: [rules.latlong],
    zipCodeRules: [rules.zipCode],
    postalCodeRules: [rules.postalCode],
    appSelectionRules: [],
    appSelectionToggle: false,
    requiredRules: (msg) => [rules.required(msg)],
    sectorNames: [
      { text: 'Bon Appetit', value: 'Bon Appetit' },
      { text: 'Canteen', value: 'Canteen' },
      { text: 'Chartwells', value: 'Chartwells' },
      { text: 'CulinArt', value: 'CulinArt' },
      { text: 'Eurest', value: 'Eurest' },
      { text: 'FLIK', value: 'FLIK' },
      { text: 'Morrison', value: 'Morrison' },
      { text: 'Restaurant Associates', value: 'Restaurant Associates' },
      { text: 'Wolfgang Puck', value: 'Wolfgang Puck' },
    ],
  }),
  computed: {
    ...mapGetters('sites', {
      multigroups: 'getCdlMultigroups',
      isDHSite: 'isDHSite',
    }),
    ...mapState('sites', ['active_site']),
    isDH() {
      return this.isDHSite(this.value);
    },
    codeLabel() {
      let labelPrefix = 'Postal';
      if (this.selectedCountry === 'US') labelPrefix = 'Zip';

      return `${labelPrefix} Code`;
    },
    states() {
      return provinces
        .filter((prov) => {
          if (this.selectedCountry) return prov.country === this.selectedCountry;
          return prov.country === 'US' || prov.country === 'CA';
        })
        .map((prov) => ({ text: prov.name, value: prov.short }));
    },
    addressCodeRules() {
      let rule = this.requiredRules('Postal Code is missing');
      if (this.selectedCountry === 'US') rule = this.zipCodeRules;
      else if (this.selectedCountry === 'CA') rule = this.postalCodeRules;

      return rule;
    },
    selectedCountry() {
      return (this.value && this.value.address && this.value.address.country) || null;
    },
    latLong: {
      get() {
        const { coordinates } = this.value;
        try {
          return coordinates && coordinates.latitude && coordinates.longitude
            ? `${this.value.coordinates.latitude},${this.value.coordinates.longitude}`
            : '';
        } catch (err) {
          return '';
        }
      },
      set(value) {
        if (!this.latlongRules.every((r) => r(value) === true)) return;
        const coordinates = value.split(',');
        this.$emit('input', {
          ...this.value,
          coordinates: {
            latitude: Number(coordinates[0]),
            longitude: Number(coordinates[1]),
          },
        });
      },
    },
    logoImageProxy: {
      get() {
        return (this.value && this.value.style && this.value.style.logo) || '';
      },
      set(v) {
        this.$emit('input', {
          ...this.value,
          style: {
            ...this.value.style,
            logo: v,
          },
        });
      },
    },
    timezone() {
      return (
        !this.isNewSite &&
        this.active_site &&
        this.active_site.locations &&
        this.active_site.locations[0] &&
        this.active_site.locations[0].brands &&
        this.active_site.locations[0].brands[0] &&
        this.active_site.locations[0].brands[0].timezone
      );
    },
    isExternalPartner: {
      get() {
        return this.value.meta && typeof this.value.meta.external_partner === 'string';
      },
      set(isExternal) {
        const selectedMultigroup = this.multigroups.find((m) => m.id === this.value.app);
        if (!selectedMultigroup || !selectedMultigroup.name) return;
        this.$emit('input', {
          ...this.value,
          meta: {
            ...this.value.meta,
            external_partner: isExternal ? selectedMultigroup.name.toLowerCase() : undefined,
          },
        });
      },
    },
    sectorName: {
      get() {
        return this.value.meta && this.value.meta.sector_name;
      },
      set(name) {
        if (!name) return;

        this.$emit('input', {
          ...this.value,
          meta: {
            ...this.value.meta,
            sector_name: name,
          },
        });
      },
    },
  },
  async mounted() {
    if (!this.multigroups || this.multigroups.length === 0)
      this.$store.dispatch('sites/fetchMultigroups');
    const multigroupIdParts = ID(this.$route.params.app);
    if (multigroupIdParts && multigroupIdParts.id !== 'all') {
      this.value.app = this.$route.params.app;
    }
    this.appSelectionRules = [() => !!this.value.app || 'App Selection is Required'];
  },
  methods: {
    // setLogoChanged() {
    //   this.$emit('input', {
    //     ...this.value,
    //     style: {
    //       ...this.value.style,
    //       logo: `changed_${new Date().toISOString()}`,
    //     },
    //   });
    // },
    select(app) {
      if (this.value.app === this.$route.params.app) {
        return;
      }
      this.$emit('input', { ...this.value, app });
    },
    setPlace(place) {
      if (place) {
        this.$emit('input', {
          ...this.value,
          address: {
            state: place.state,
            zip: place.zip,
            country: place.country,
            address: place.address,
            city: place.locality,
          },
          coordinates: {
            latitude: place.coordinates.lat,
            longitude: place.coordinates.lng,
          },
        });
      }
    },
    async save() {
      if (this.isDH) return true;
      return this.$refs.logoImage.save();
    },
  },
};
</script>

<style>
App-Name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.App-Container {
  width: 160px;
  min-height: 204px;
  border-radius: 4px;
  margin: 0px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  cursor: pointer;
}
.App-Image-Box {
  width: 100%;
  height: 160px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  background-color: lightgrey;
}
.App-Image {
  position: absolute;
  z-index: 0;
}
.Selected-App {
  border: solid 1px #38a3fb;
}
.Selected-Icon {
  color: #38a3fb !important;
  position: relative;
  left: 128px;
  top: 4px;
}
.Selected-Icon-Box {
  position: absolute;
  z-index: 1;
}

.App-Not-Selected-Error {
  padding: 0 !important;
}

.App-Not-Selected-Error > .v-input__control > .v-input__slot {
  display: none !important;
}
.disabled-app {
  cursor: auto;
}
.timezone {
  margin: -1em 0 1em 1.75em;
}
</style>
