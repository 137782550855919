<template>
  <v-card style="min-height: 400px;">
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Promotions</div>
    </v-card-title>
    <div class="v-card-content">
      <v-layout style="margin-bottom: 30px;">
        <v-data-table
          :no-data-text="loading ? 'Please wait while data is loading' : 'No promotions found'"
          :headers="headers"
          :items="availablePromos"
          :loading="loading"
          width="920px"
        >
          <template v-slot:items="props">
            <tr
              style="height 50px !important; width: 950px !important;"
              @mouseover="showActions = true"
              @mouseleave="showActions = false"
            >
              <td>{{ props.item.name }}</td>
              <td>
                {{ props.item.discountType === 'AMOUNT' ? '$' : '' }}{{ getPromoAmount(props.item)
                }}{{ props.item.discountType === 'PERCENT' ? '%' : '' }}
              </td>
              <td>
                <div
                  class="active-promotion Body-1-Selected-On-Secondary-High-Emphasis-Left"
                  v-if="props.item.active"
                >
                  Active
                </div>
              </td>
              <td v-show="isAdmin">
                <v-btn flat icon class="ma-0" v-show="showActions" @click="edit(props.item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn flat icon class="ma-0" v-show="showActions" @click="remove(props.item)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-layout>
      <v-footer absolute height="60" style="background: white !important; !important;">
        <v-layout row>
          <v-flex xs11></v-flex>
          <v-flex shrink>
            <v-btn flat color="blue" @click="createPromo" style="margin-left: 30px;"
              >CREATE PROMO</v-btn
            >
          </v-flex>
        </v-layout>
      </v-footer>
      <v-dialog persistent v-model="dialog" width="600">
        <v-card>
          <v-card-title class="H5-Primary-Left">Edit Promotion</v-card-title>
          <v-form ref="form" class="py-2 px-3">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  label="Promotion Name"
                  v-model="promoEdit.name"
                  :rules="nameRules"
                ></v-text-field>
              </v-flex>
              <v-flex xs8 style="padding-right: 25px;">
                <v-select
                  label="Promotion Type"
                  v-model="promoEdit.discountType"
                  :disabled="!!promoEdit.id"
                  :items="promoTypes"
                  :rules="promoTypeRules"
                />
              </v-flex>
              <v-flex xs4 v-if="!promoEdit.code">
                <v-text-field
                  label="Value ($ or %)"
                  v-model="promoEdit.amount"
                  :disabled="!!promoEdit.id"
                  type="number"
                  :rules="promoValueRules"
                />
              </v-flex>
              <v-flex xs4 v-if="!!promoEdit.code">
                <v-text-field
                  label="Value ($ or %)"
                  :value="getPromoAmount(promoEdit)"
                  :disabled="!!promoEdit.id"
                  @input="(amount) => setPromoAmount(promoEdit, amount)"
                  type="number"
                  :rules="promoValueRules"
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  label="Promotion State"
                  v-model="promoEdit.active"
                  :items="promoActiveStates"
                  :rules="promoStateRules"
                />
              </v-flex>
              <v-flex xs12>
                <div class="Body-1-Selected-On-Surface-Medium-Emphasis-Left">
                  Promotion applies to these payment methods
                </div>
              </v-flex>
              <v-flex xs12 nowrap class="flex-col-reverse">
                <div v-if="appName === 'thrive'" style="display:flex; flex-wrap:nowrap">
                  <v-checkbox
                    v-model="promoEdit.is_badgepay_promo"
                    label="Badge Pay Member Account"
                  />
                </div>
                <v-checkbox v-else v-model="promoEdit.is_mealplan_promo" label="Mealplans" />
              </v-flex>
            </v-layout>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions style="padding: 15px;">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="cancelEdit">cancel</v-btn>
            <v-btn color="primary" @click="saveChanges">save changes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters, mapState } from 'vuex';
import rules from '@/rules';
import { allowedMultigroupsPromotion } from '@/constants';

export default {
  name: 'location-promotion',
  props: {
    promotions: {
      default: () => [],
      type: Array,
    },
    originalPromos: {
      default: () => [],
      type: Array,
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Promotion Name',
        align: 'left',
        value: 'name',
        width: '300px',
      },
      {
        text: 'Discount',
        align: 'left',
        value: 'discount',
        width: '300px',
      },
      {
        text: '',
        align: 'left',
        value: 'active',
        sortable: false,
        width: '200px',
      },
      {
        text: '',
        align: 'left',
        value: 'edit',
        sortable: false,
        width: '150px',
      },
    ],
    loading: false,
    filter: '',
    pagination: {
      rowsPerPage: 10,
    },
    promoTypes: [
      {
        text: 'Percentage off',
        value: 'PERCENT',
      },
      {
        text: 'Amount off',
        value: 'AMOUNT',
      },
    ],
    promoActiveStates: [
      {
        text: 'Active',
        value: true,
      },
      {
        text: 'Disabled',
        value: false,
      },
    ],
    nameRules: [rules.required('Promotion name is required')],
    promoTypeRules: [rules.required('Promotion type is required')],
    promoStateRules: [rules.required('Promotion State is required', [false])],
    showActions: false,
    promoTemplate: {
      name: null,
      discountType: null,
      active: false,
      deleted: null,
      amount: null,
      code: null,
      is_mealplan_promo: false,
      is_badgepay_promo: false,
    },
    promoEdit: { active: false, name: null },
    dialog: false,
  }),
  computed: {
    ...mapState('sites', ['multigroupMap', 'multigroups']),
    ...mapGetters('adminPanel', ['isAdmin', 'getAppName']),
    promoValueRules() {
      const r = [rules.required('Promotion Value is Required')];
      r.push(rules.greaterThan(0));
      if (this.promoEdit.discountType === 'PERCENT') {
        r.push(rules.max(100));
      }
      return r;
    },
    appName() {
      return allowedMultigroupsPromotion.find(
        (app) => app === this.getAppName(this.$route.params.app).toLowerCase(),
      );
    },
    availablePromos: {
      get() {
        return this.promotions.filter((p) => !p.deleted);
      },
      set(v) {
        this.$emit('update:promotions', v);
      },
    },
  },
  mounted() {
    this.$set(this, 'availablePromos', this.promotions);
  },
  methods: {
    getPromoAmount(promoItem) {
      if (promoItem.discountType === 'AMOUNT') {
        return (Number(promoItem.amount) / 100).toFixed(2);
      }
      return promoItem.amount;
    },
    edit(promoItem) {
      // find the promo to edit
      this.$set(this, 'promoEdit', cloneDeep(promoItem));
      this.dialog = true;
    },
    cancelEdit() {
      this.dialog = false;
      // reset all fields here
      this.$set(this, 'promoEdit', cloneDeep(this.promoTemplate));
      this.$refs.form.reset();
    },
    createPromo() {
      this.dialog = true;
      this.$refs.form.reset();
      this.$set(this, 'promoEdit', cloneDeep(this.promoTemplate));
    },
    saveChanges() {
      if (this.$refs.form.validate()) {
        // if its enabled, all the other ones should be disabled
        if (this.promoEdit.active) {
          this.availablePromos.forEach((p) => {
            if (p.active !== false && p.id) {
              this.$set(p, 'updated', true);
            }
            this.$set(p, 'active', false);
          });
        }

        if (!this.promoEdit.code) {
          const app = this.appName;

          const promoEdit = cloneDeep(this.promoEdit);
          if (promoEdit.discountType === 'AMOUNT') {
            promoEdit.amount = Number(promoEdit.amount) * 100;
          }
          this.availablePromos.push(
            cloneDeep({
              ...promoEdit,
              code: `LOCATION-${
                Math.random()
                  .toString()
                  .split('0.')[1]
              }`,
              app,
            }),
          );
        } else {
          const promoEditClone = cloneDeep(this.promoEdit);
          const promoToUpdate = this.availablePromos.find((p) => p.code === this.promoEdit.code);
          if (promoToUpdate.id) {
            const originalPromo = this.originalPromos.find((p) => p.id === promoToUpdate.id);
            const isUpdated = !this.isPromoEqual(promoEditClone, originalPromo);
            if (isUpdated) {
              this.$set(promoToUpdate, 'updated', true);
            } else {
              this.$delete(promoToUpdate, 'updated');
            }
          }
          this.$set(promoToUpdate, 'name', promoEditClone.name);
          this.$set(promoToUpdate, 'discountType', promoEditClone.discountType);
          this.$set(promoToUpdate, 'active', promoEditClone.active);
          this.$set(promoToUpdate, 'amount', promoEditClone.amount);
          this.$set(promoToUpdate, 'is_mealplan_promo', promoEditClone.is_mealplan_promo);
          this.$set(promoToUpdate, 'is_badgepay_promo', promoEditClone.is_badgepay_promo);
        }
        this.$refs.form.reset();
        this.dialog = false;
        this.$emit('update:promotions', this.availablePromos);
      } else {
        this.$toast.error('Please fix the errors');
      }
    },
    setPromoAmount(promo, amount) {
      this.$set(promo, 'amount', promo.discountType === 'AMOUNT' ? amount * 100 : amount);
    },
    remove(item) {
      const promoToUpdate = this.availablePromos.find((p) => p.code === item.code);
      this.$set(promoToUpdate, 'deleted', true);
      this.$emit('update:promotions', this.availablePromos);
    },
    isPromoEqual(p1, p2) {
      return (
        p1.name === p2.name &&
        p1.discountType === p2.discountType &&
        p1.active === p2.active &&
        p1.amount === p2.amount &&
        p1.is_mealplan_promo === p2.is_mealplan_promo &&
        p1.is_badgepay_promo === p2.is_badgepay_promo &&
        p1.is_declining_balance_promo === p2.is_declining_balance_promo &&
        p1.is_voucher_promo === p2.is_voucher_promo
      );
    },
  },
};
</script>

<style scoped>
div.active-promotion {
  width: 72px;
  height: 32px;
  border-radius: 4px;
  background-color: #0a43a7;
  padding: 2px 10px 0 10px;
}
table.v-table tbody td,
table.v-table tbody th {
  height: 60px;
}
</style>
