import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(
        VCardTitle,
        { attrs: { "primary-title": "" } },
        [
          _c("div", { staticClass: "H5-Primary-Left" }, [_vm._v("Badge Pay")]),
          _c(VSpacer),
          _vm.value && _vm.selectedProviderType
            ? _c(
                VBtn,
                {
                  attrs: {
                    flat: "",
                    color: "secondary",
                    disabled: _vm.isSectionDisabled
                  },
                  on: {
                    click: function($event) {
                      _vm.showRemoveDialog = true
                    }
                  }
                },
                [
                  _c(VIcon, { staticClass: "mr-1", attrs: { left: "" } }, [
                    _vm._v("mdi-delete-outline")
                  ]),
                  _vm._v("remove\n    ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "v-card-content" },
        [
          _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs8: "" } },
                [
                  _c(VSelect, {
                    attrs: {
                      label: "Plan Type",
                      placeholder: "Choose a Plan",
                      rules: [].concat(_vm.validateFPConfig),
                      items: _vm.providerTypes,
                      value: _vm.selectedProviderType,
                      disabled: _vm.isSectionDisabled
                    },
                    on: { input: _vm.selectProvider }
                  })
                ],
                1
              ),
              _c(VFlex, { attrs: { x4: "" } })
            ],
            1
          ),
          _vm.selectedProviderType
            ? _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    VFlex,
                    { attrs: { xs8: "" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          label: "Plan Name",
                          value: _vm.value.name,
                          rules: _vm.requiredField,
                          disabled: _vm.isSectionDisabled
                        },
                        on: {
                          input: function($event) {
                            _vm.value.name = _vm.safeTrim($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          label: "Webview URL",
                          value: _vm.value.webview,
                          rules: _vm.requiredField.concat(_vm.isURL),
                          disabled: _vm.isSectionDisabled
                        },
                        on: {
                          input: function($event) {
                            _vm.value.webview = _vm.safeTrim($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(VTextField, {
                        ref: "system_key",
                        attrs: {
                          label: "System Key",
                          value: _vm.value.badge_pay_system_key,
                          rules: _vm.requiredField,
                          disabled: _vm.isSectionDisabled
                        },
                        on: {
                          input: function($event) {
                            _vm.value.badge_pay_system_key = _vm.safeTrim(
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(VSelect, {
                        attrs: {
                          label: "Tax Exempt",
                          placeholder: "Select Tax Exemption",
                          rules: _vm.requiredFieldTaxExempt,
                          items: _vm.taxExemptionOptions,
                          value: _vm.getTaxExemption,
                          disabled: _vm.isSectionDisabled
                        },
                        on: { input: _vm.setTaxExemption }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "add-tender-btn" },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { flat: "", color: "primary" },
                          on: { click: _vm.createCashlessTender }
                        },
                        [
                          _c(VIcon, [_vm._v("mdi-plus")]),
                          _c("span", [_vm._v("add another tender type")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(VFlex, { attrs: { xs4: "" } })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("remove-dialog", {
        attrs: {
          title: "Remove Badge Pay",
          descriptionLines: [
            "Removing cannot be undone.",
            "To continue with removal please enter REMOVE in the field below and continue."
          ],
          onConfirmHandler: _vm.remove,
          disabled: _vm.isSectionDisabled
        },
        model: {
          value: _vm.showRemoveDialog,
          callback: function($$v) {
            _vm.showRemoveDialog = $$v
          },
          expression: "showRemoveDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }