import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(
        VCardTitle,
        { attrs: { "primary-title": "" } },
        [
          _c("div", { staticClass: "H5-Primary-Left" }, [
            _vm._v("Cashless Pay")
          ]),
          _c(VSpacer),
          _vm.value && _vm.selectedProviderType
            ? _c(
                VBtn,
                {
                  attrs: {
                    flat: "",
                    color: "secondary",
                    disabled: _vm.isSectionDisabled
                  },
                  on: {
                    click: function($event) {
                      _vm.showRemoveDialog = true
                    }
                  }
                },
                [
                  _c(VIcon, { staticClass: "mr-1", attrs: { left: "" } }, [
                    _vm._v("mdi-delete-outline")
                  ]),
                  _vm._v("remove\n    ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "v-card-content" },
        [
          _c(VSelect, {
            staticClass: "cashless-pay__select mb-2",
            attrs: {
              label: "Plan Type",
              placeholder: "Choose a Plan",
              rules: [].concat(_vm.validateFPConfig),
              items: _vm.providerTypes,
              value: _vm.selectedProviderType,
              disabled: _vm.isSectionDisabled
            },
            on: { input: _vm.selectProvider }
          }),
          _vm.selectedProviderType
            ? _c(
                "div",
                [
                  _c(VTextField, {
                    staticClass: "cashless-pay__text-field mb-2",
                    attrs: {
                      label: "Plan Name",
                      value: _vm.value.name,
                      rules: _vm.requiredField,
                      disabled: _vm.isSectionDisabled
                    },
                    on: {
                      input: function($event) {
                        _vm.value.name = _vm.safeTrim($event)
                      }
                    }
                  }),
                  _c(VTextField, {
                    staticClass: "cashless-pay__text-field mb-2",
                    attrs: {
                      label: "Account Webview URL",
                      value: _vm.value.webview,
                      rules: _vm.requiredField.concat(_vm.isWebViewURL),
                      disabled: _vm.isSectionDisabled
                    },
                    on: {
                      input: function($event) {
                        _vm.value.webview = _vm.safeTrim($event)
                      }
                    }
                  }),
                  _c(VTextField, {
                    staticClass: "cashless-pay__text-field mb-2",
                    attrs: {
                      label: "Voucher Webview URL",
                      value: _vm.value.voucher_webview,
                      rules: _vm.requiredField.concat(_vm.isVoucherWebViewURL),
                      disabled: _vm.isSectionDisabled
                    },
                    on: {
                      input: function($event) {
                        _vm.value.voucher_webview = _vm.safeTrim($event)
                      }
                    }
                  }),
                  _c(VTextField, {
                    ref: "system_key",
                    staticClass: "cashless-pay__text-field mb-2",
                    attrs: {
                      label: "System Key",
                      value: _vm.value.badge_pay_system_key,
                      rules: _vm.requiredField,
                      disabled: _vm.isSectionDisabled
                    },
                    on: {
                      input: function($event) {
                        _vm.value.badge_pay_system_key = _vm.safeTrim($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "tender-list" },
                    [
                      _vm._l(_vm.tenders, function(tender, index) {
                        return _c(
                          "div",
                          {
                            key: "brand-tender-" + index,
                            staticClass: "d-flex tender"
                          },
                          [
                            _c(
                              VIcon,
                              {
                                staticClass: "mx-2 tender__remove-btn",
                                style: {
                                  opacity: _vm.value.tenders.length > 1 ? 1 : 0,
                                  cursor:
                                    _vm.value.tenders.length > 1
                                      ? "pointer"
                                      : "default"
                                },
                                attrs: { large: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeTender(index)
                                  }
                                }
                              },
                              [_vm._v("mdi-close")]
                            ),
                            _c(VSelect, {
                              staticClass: "tender__select",
                              attrs: {
                                label: "Tender Type",
                                placeholder: "Please select Tender Type",
                                rules: _vm.requiredField,
                                value: tender.tender_type,
                                items: _vm.cashlessTenderTypes
                              },
                              on: {
                                input: function($event) {
                                  _vm.value.tenders[index].tender_type = $event
                                }
                              }
                            }),
                            _c(VTextField, {
                              staticClass: "tender__text-field",
                              attrs: {
                                label: "Tender name",
                                placeholder: "Please insert name of Tender",
                                rules: _vm.requiredField,
                                value: tender.name
                              },
                              on: {
                                input: function($event) {
                                  _vm.value.tenders[index].name = _vm.safeTrim(
                                    $event
                                  )
                                }
                              }
                            }),
                            _c(VSelect, {
                              staticClass: "tender__select",
                              attrs: {
                                label: "Tax Exempt",
                                placeholder: "Please select tax exempt",
                                items: _vm.TAX_EXEMPT,
                                value: tender.tax_exempt,
                                rules: _vm.requiredFieldTaxExempt
                              },
                              on: {
                                input: function($event) {
                                  _vm.value.tenders[index].tax_exempt = $event
                                }
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _c(
                        VBtn,
                        {
                          staticClass: "add-tender-btn",
                          attrs: { flat: "", color: "primary" },
                          on: { click: _vm.createNewTender }
                        },
                        [
                          _c(VIcon, [_vm._v("mdi-plus")]),
                          _c("span", [_vm._v("add another tender type")])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("remove-dialog", {
        attrs: {
          title: "Remove Badge Pay",
          descriptionLines: [
            "Removing cannot be undone.",
            "To continue with removal please enter REMOVE in the field below and continue."
          ],
          onConfirmHandler: _vm.remove,
          disabled: _vm.isSectionDisabled
        },
        model: {
          value: _vm.showRemoveDialog,
          callback: function($$v) {
            _vm.showRemoveDialog = $$v
          },
          expression: "showRemoveDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }