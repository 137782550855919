<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Delivery Drop-off Locations</div>
    </v-card-title>
    <div class="v-card-content">
      <v-layout>
        <v-flex xs6>
          <v-text-field
            label="Search Locations"
            prepend-inner-icon="mdi-magnify"
            clearable
            v-model="filter"
            hide-details
          />
        </v-flex>
      </v-layout>
      <v-layout style="margin-bottom: 12px;">
        <v-flex>
          <v-data-table
            :headers="headers"
            :items="availableDropOffLocations"
            :search="filter"
            :pagination.sync="pagination"
          >
            <template slot="headers" slot-scope="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.name"
                  :class="[
                    'column sortable',
                    pagination.descending ? 'desc' : 'asc',
                    header.value === pagination.sortBy ? 'active' : '',
                  ]"
                  scope="col"
                  style="text-align: left;"
                  @click="changeSort(header.value)"
                  :width="header.width"
                >
                  {{ header.text }}
                  <v-icon small>mdi-arrow-up</v-icon>
                </th>
                <th scope="col" width="20%" style="min-width: 135px;" />
              </tr>
            </template>
            <template slot="no-data">
              <td class="text-xs-center" style="" colspan="3">No locations found</td>
            </template>
            <template slot="no-results">
              <td class="text-xs-center" style="" colspan="3">No locations found</td>
            </template>
            <template v-slot:items="props">
              <tr
                style="height: 50px !important; width: 950px !important;"
                @mouseover="showActions = props.item.id"
                @mouseleave="showActions = null"
              >
                <td style="white-space: nowrap;">
                  {{ props.item.name }}
                </td>
                <td>
                  <span
                    v-if="props.item.foodlocker"
                    class="foodlocker Body-1-Selected-On-Secondary-High-Emphasis-Left"
                  >
                    Foodlocker
                  </span>
                </td>
                <td style="text-align: right;">
                  <div v-show="isAdmin">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          flat
                          icon
                          class="ma-0"
                          v-bind="attrs"
                          v-on="on"
                          v-show="showActions === props.item.id"
                          @click="edit(props.item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Location</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          flat
                          icon
                          class="ma-0"
                          v-bind="attrs"
                          v-on="on"
                          v-show="showActions === props.item.id"
                          @click="duplicate(props.item)"
                        >
                          <v-icon small>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>Duplicate Location</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          flat
                          icon
                          class="ma-0"
                          v-bind="attrs"
                          v-on="on"
                          v-show="showActions === props.item.id"
                          @click="remove(props.item)"
                        >
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Remove Location</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex style="text-align: right;">
          <v-tooltip bottom :disabled="!isDisabled">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" style="display: inline-block">
                <v-btn
                  flat
                  color="blue"
                  @click="addDropOffLocation"
                  style="margin: 0;"
                  v-bind="attrs"
                  :disabled="isDisabled"
                >
                  CREATE DROP-OFF LOCATION
                </v-btn>
              </div>
            </template>
            <span>This feature is not available for this site.</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
      <v-dialog v-model="dialogRemoveConfirm" width="600">
        <v-card>
          <v-card-title
            class="H4-Primary-Left pt-5"
            style="padding-right: 40px; padding-left: 40px;"
          >
            Remove Location?
          </v-card-title>
          <v-flex>
            <p class="Body-1-Black-High-Emphasis-Left p-remove-spacing">
              Are you sure you want to remove this drop-off location? It will take the location off
              the page and the timeslot associated with it will be removed at the same time.
            </p>
          </v-flex>
          <v-card-actions style="padding: 0 40px 40px;">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="closeRemoveModal">CANCEL</v-btn>
            <v-btn color="primary" flat @click="confirmRemove">REMOVE</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" width="640">
        <v-card>
          <v-card-title
            class="H4-Primary-Left pt-5"
            style="padding-right: 40px; padding-left: 40px;"
          >
            {{ dialogFormTitle }}
          </v-card-title>
          <v-form ref="form" style="padding: 8px 40px 32px;">
            <v-flex class="mb-3">
              <v-text-field
                label="Location Name"
                v-model="dropOffLocationForm.name"
                maxlength="80"
                hint="80 characters max, this name will appear on the consumer apps."
                :rules="nameRules"
              />
            </v-flex>
            <v-flex class="mb-3">
              <location-autocomplete
                v-model="dropOffLocationForm.address"
                :rules="addressRules"
                @autocomplete="setPlace"
              />
            </v-flex>
            <v-layout class="mb-3">
              <v-flex xs6 class="mr-4">
                <v-autocomplete
                  label="Country"
                  placeholder="Choose Country"
                  v-model="dropOffLocationForm.country"
                  :items="countries"
                  :rules="countryRules"
                  ref="countries"
                  @blur="blurSelect('countries')"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field label="City" v-model="dropOffLocationForm.city" :rules="cityRules" />
              </v-flex>
            </v-layout>
            <v-layout class="mb-3">
              <v-flex xs6 class="mr-4">
                <v-autocomplete
                  v-model="dropOffLocationForm.state"
                  :placeholder="statePlaceholder"
                  :label="stateLabel"
                  :items="states"
                  :rules="stateRules"
                  ref="states"
                  @blur="blurSelect('states')"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="dropOffLocationForm.zip"
                  maxlength="9"
                  :label="codeLabel"
                  :rules="addressCodeRules"
                />
              </v-flex>
            </v-layout>
            <v-flex class="mt-4 mb-4">
              <div style="position: relative;">
                <v-textarea
                  label="Location Information (optional)"
                  v-model="dropOffLocationForm.information"
                  name="dropOffLocationInformation"
                  rows="2"
                  :maxlength="textareaCharLimit"
                  hide-details
                  outline
                  no-resize
                />
                <span style="position: absolute; bottom: 8px; right: 12px;">
                  {{ textAreaCharLength }}
                </span>
              </div>
              <p
                class="mt-2"
                style="color: rgba(0, 0, 0, 0.6); font-size: 13.5467px; line-height: 16px; letter-spacing: 0.4px;"
              >
                This message will appear at checkout and in the order confirmation notification.
              </p>
            </v-flex>
            <v-flex xs7 class="mt-2">
              <p
                class="mb-2"
                style="color: rgba(0, 0, 0, 0.6); font-size: 18px; line-height: 24px; letter-spacing: 0.15px;"
              >
                Is this an APEX Foodlocker?
              </p>
              <toggle
                v-model="dropOffLocationForm.foodlocker"
                position="'start'"
                style="margin: 0 !important;"
              />
            </v-flex>
          </v-form>
          <v-card-actions style="padding: 0 40px 40px;">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="close">cancel</v-btn>
            <v-btn color="primary" @click="saveChanges">
              {{ dropOffLocationForm.id ? 'Save Changes' : 'Create Location' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import axios from 'axios';
import { v4 } from 'uuid';
import rules from '@/rules';
import provinces from '@/assets/provinces.json';
import locationAutocomplete from '@/components/location-autocomplete';

const { CancelToken } = axios;

export default {
  name: 'location-promotion',
  props: {
    dropOffLocations: {
      default: () => [],
      type: Array,
    },
    isDisabled: {
      type: Boolean,
    },
  },
  components: {
    locationAutocomplete,
  },
  data: () => ({
    countries: [
      {
        text: 'Canada',
        value: 'CA',
      },
      {
        text: 'United States',
        value: 'US',
      },
    ],
    headers: [
      {
        text: 'Location Name',
        value: 'name',
        width: '63%',
      },
      {
        text: 'Location Type',
        value: 'foodlocker',
        width: '17%',
      },
    ],
    filter: '',
    pagination: {
      rowsPerPage: 5,
      sortBy: 'name',
    },
    dropOffLocationForm: {
      id: '',
      name: '',
      foodlocker: false,
      information: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      longitude: '',
      latitude: '',
    },
    locationToRemove: null,
    duplicateLocation: false,
    showActions: false,
    dialog: false,
    dialogRemoveConfirm: null,
    textareaCharLimit: 100,
    max_patch_or_delete_items: 25,
    addressRules: [rules.required('Address is required')],
    cityRules: [rules.required('City is required')],
    requestCancel: null,
    invalidAddressCode: null,
    invalidAddressCodeErrMessage: 'INVALID_ADDRESS_CODE',
  }),
  computed: {
    ...mapGetters('adminPanel', ['isAdmin']),
    ...mapState('sites', ['active_site']),
    dialogFormTitle() {
      if (this.dropOffLocationForm.id) {
        return 'Edit Drop-off Location';
      }

      if (this.duplicateLocation) {
        return 'Duplicate Drop-off Location';
      }

      return 'Create Drop-off Location';
    },
    codeLabel() {
      if (this.dropOffLocationForm.country) {
        return this.dropOffLocationForm.country === 'US' ? 'Zip Code' : 'Postal Code';
      }
      return 'Zip or Postal Code';
    },
    stateLabel() {
      if (this.dropOffLocationForm.country) {
        return this.dropOffLocationForm.country === 'US' ? 'State' : 'Province';
      }
      return 'State or Province';
    },
    statePlaceholder() {
      if (this.dropOffLocationForm.country) {
        return this.dropOffLocationForm.country === 'US' ? 'Choose State' : 'Choose Province';
      }
      return 'Choose State or Province';
    },
    textAreaCharLength() {
      const charLength = this.dropOffLocationForm.information
        ? this.dropOffLocationForm.information.length
        : 0;
      return `${charLength}/${this.textareaCharLimit}`;
    },
    states() {
      if (!this.dropOffLocationForm.country) {
        return provinces.map((prov) => ({ text: prov.name, value: prov.short }));
      }

      return provinces
        .filter((prov) => prov.country === this.dropOffLocationForm.country)
        .map((filteredProv) => ({ text: filteredProv.name, value: filteredProv.short }));
    },
    nameRules() {
      return [
        rules.required('Location name is required'),
        () => {
          const nameFound = this.availableDropOffLocations.find((location) => {
            if (!location.id) {
              return location.name === this.dropOffLocationForm.name.trim();
            }

            return (
              location.name === this.dropOffLocationForm.name.trim() &&
              location.id !== this.dropOffLocationForm.id
            );
          });
          if (nameFound) {
            if (this.duplicateLocation) {
              return 'Location name is already used, please make sure it’s a unique location name.';
            }
            return 'This location name already exists';
          }

          return true;
        },
      ];
    },
    stateRules() {
      if (!this.dropOffLocationForm.state) {
        if (!this.dropOffLocationForm.country) {
          return ['State or Province is required'];
        }
        return this.dropOffLocationForm.country === 'CA'
          ? ['Province is required']
          : ['State is required'];
      }
      const foundState = provinces.find((province) => {
        if (this.dropOffLocationForm.country) {
          return (
            province.short === this.dropOffLocationForm.state &&
            province.country === this.dropOffLocationForm.country
          );
        }

        return province.short === this.dropOffLocationForm.state;
      });
      if (foundState) {
        return [true];
      }
      return this.dropOffLocationForm.country === 'CA'
        ? ['Province is required']
        : ['State is required'];
    },
    addressCodeRules() {
      if (!this.dropOffLocationForm.country) {
        if (!this.dropOffLocationForm.zip) return ['Zip or Postal Code is required'];
      } else {
        if (!this.dropOffLocationForm.zip) {
          return this.dropOffLocationForm.country === 'CA'
            ? ['Postal Code is required']
            : ['Zip Code is required'];
        }
        if (this.invalidAddressCode === this.dropOffLocationForm.zip) {
          return this.dropOffLocationForm.country === 'CA'
            ? ['Postal Code is Invalid']
            : ['Zip Code is Invalid'];
        }

        return this.dropOffLocationForm.country === 'CA'
          ? [rules.postalCode(this.dropOffLocationForm.zip)]
          : [rules.zipCode(this.dropOffLocationForm.zip)];
      }
      // if no country selected only required validation
      // is needed, same as site address fields
      return [true];
    },
    countryRules() {
      return [
        rules.required('Country is required'),
        () => {
          if (
            this.active_site &&
            this.active_site.address &&
            this.active_site.address.country &&
            !this.isNewSite
          ) {
            if (this.dropOffLocationForm.country !== this.active_site.address.country) {
              return 'Location country should match site country';
            }
          }
          return true;
        },
      ];
    },
    availableDropOffLocations: {
      get() {
        return this.dropOffLocations.filter((location) => location.action !== 'DELETE');
      },
      set(locations) {
        this.$emit('update:dropOffLocations', locations);
      },
    },
    isNewSite() {
      return !this.$route.params.site_id;
    },
  },
  mounted() {
    this.$set(this.availableDropOffLocations, this.dropOffLocations);
  },
  unmounted() {
    if (this.requestCancel) {
      this.requestCancel();
    }
  },
  methods: {
    ...mapActions('sites', ['postDeliveryDestination']),
    addressCodeCountryRule() {
      if (!this.dropOffLocationForm.zip) {
        return this.dropOffLocationForm.country === 'CA'
          ? ['Postal Code is required']
          : ['Zip Code is required'];
      }
      if (this.invalidAddressCode === this.dropOffLocationForm.zip) {
        return this.dropOffLocationForm.country === 'CA'
          ? ['Postal Code is Invalid']
          : ['Zip Code is Invalid'];
      }

      return this.dropOffLocationForm.country === 'CA'
        ? [rules.postalCode(this.dropOffLocationForm.zip)]
        : [rules.zipCode(this.dropOffLocationForm.zip)];
    },
    changeSort(columnName) {
      if (this.pagination.sortBy === columnName) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = columnName;
        this.pagination.descending = false;
      }
    },
    setPlace(place) {
      if (place) {
        this.dropOffLocationForm = {
          ...this.dropOffLocationForm,
          address: place.address,
          state: place.state,
          zip: place.zip,
          country: place.country,
          city: place.locality,
          latitude: place.coordinates.lat,
          longitude: place.coordinates.lng,
        };
      }
    },
    edit(item) {
      const editedLocations = this.dropOffLocations.filter(
        // will allow user to edit an already edited location
        (dropOffLoc) => dropOffLoc.action === 'UPDATE' && dropOffLoc.id !== item.id,
      );
      // don't allow user to edit more than dynamo transaction allows
      if (editedLocations.length >= this.max_patch_or_delete_items) {
        this.$toast.error(
          `Cannot edit more than ${this.max_patch_or_delete_items} drop-off locations at once. Please save current changes before continuing.`,
        );
      } else {
        this.dropOffLocationForm = {
          id: item.id,
          name: item.name,
          foodlocker: item.foodlocker,
          information: item.information,
          address: item.address.address,
          city: item.address.city,
          state: item.address.state,
          country: item.address.country,
          zip: item.address.zip,
          longitude: item.address.coordinates.longitude,
          latitude: item.address.coordinates.latitude,
        };
        this.dialog = true;
      }
    },
    duplicate(item) {
      this.dropOffLocationForm = {
        name: item.name,
        foodlocker: item.foodlocker,
        information: item.information,
        address: item.address.address,
        city: item.address.city,
        state: item.address.state,
        country: item.address.country,
        zip: item.address.zip,
      };
      this.duplicateLocation = true;
      this.dialog = true;
    },
    close() {
      this.$refs.form.reset();
      this.dropOffLocationForm = {
        id: '',
        name: '',
        foodlocker: false,
        information: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        longitude: '',
        latitude: '',
      };
      this.dialog = false;
      this.duplicateLocation = false;
    },
    closeRemoveModal() {
      this.dialogRemoveConfirm = false;
      this.locationToRemove = null;
    },
    addDropOffLocation() {
      this.dialog = true;
      if (
        this.active_site &&
        this.active_site.address &&
        this.active_site.address.country &&
        !this.isNewSite
      ) {
        this.dropOffLocationForm.country = this.active_site.address.country;
      }
    },
    blurSelect(ref) {
      this.$refs[ref].blur();
    },
    async prepareDropOffLocation() {
      if (!this.dropOffLocationForm.latitude || !this.dropOffLocationForm.longitude) {
        const mapApi =
          'https://zpnwqjg9n4.execute-api.us-east-1.amazonaws.com/default/google-places';
        const { data } = await axios.get(mapApi, {
          params: {
            predict: `${this.dropOffLocationForm.zip},${this.dropOffLocationForm.country}`,
          },
          cancelToken: new CancelToken((c) => {
            this.requestCancel = c;
          }),
        });
        const foundLocation = data.find((loc) => {
          return loc.types.includes('postal_code');
        });
        if (!foundLocation) {
          this.invalidAddressCode = this.dropOffLocationForm.zip;
          throw new Error(this.invalidAddressCodeErrMessage);
        }

        const details = await axios.get(mapApi, {
          params: {
            id: foundLocation.place_id,
          },
        });
        this.dropOffLocationForm.longitude = details.data.geometry.location.lng;
        this.dropOffLocationForm.latitude = details.data.geometry.location.lat;
      }
      if (!this.dropOffLocationForm.id) {
        this.dropOffLocationForm.id = `new-${v4()}`;
      }
      const deliveryDestinationPayload = {
        id: this.dropOffLocationForm.id,
        name: this.dropOffLocationForm.name.trim(),
        foodlocker: this.dropOffLocationForm.foodlocker,
        information: this.dropOffLocationForm.information,
        address: {
          address: this.dropOffLocationForm.address,
          city: this.dropOffLocationForm.city,
          state: this.dropOffLocationForm.state,
          zip: this.dropOffLocationForm.zip,
          country: this.dropOffLocationForm.country,
          coordinates: {
            latitude: this.dropOffLocationForm.latitude,
            longitude: this.dropOffLocationForm.longitude,
          },
        },
      };
      return deliveryDestinationPayload;
    },
    async saveChanges() {
      if (this.$refs.form.validate()) {
        try {
          if (!this.dropOffLocationForm.id) {
            const preparedCreateLocation = await this.prepareDropOffLocation();
            const updatedLocations = [
              ...this.dropOffLocations,
              {
                action: 'CREATE',
                ...preparedCreateLocation,
              },
            ];
            this.$emit('update:dropOffLocations', updatedLocations);
            this.close();
          } else {
            const { editLocation, otherLocations } = this.availableDropOffLocations.reduce(
              (acc, location) => {
                if (location.id === this.dropOffLocationForm.id) {
                  return {
                    ...acc,
                    editLocation: location,
                  };
                }
                return {
                  ...acc,
                  otherLocations: [...acc.otherLocations, location],
                };
              },
              { editLocation: null, otherLocations: [] },
            );
            if (editLocation) {
              let updatedLocation;
              const preparedEditLocation = await this.prepareDropOffLocation();
              if (editLocation.action) {
                updatedLocation = {
                  ...editLocation,
                  ...preparedEditLocation,
                };
              } else {
                updatedLocation = {
                  ...editLocation,
                  ...preparedEditLocation,
                  action: 'UPDATE',
                };
              }
              this.$emit('update:dropOffLocations', [...otherLocations, updatedLocation]);
            }
            this.close();
          }
        } catch (err) {
          if (err.message === this.invalidAddressCodeErrMessage) {
            this.$refs.form.validate();
          } else {
            console.error(err);
            this.close();
            this.$toast.error('Unable to save location');
          }
        }
      } else {
        this.$toast.error('Please fix the errors');
      }
    },
    remove(location) {
      const deletedLocations = this.dropOffLocations.filter(
        (dropOffLoc) => dropOffLoc.action === 'DELETE',
      );
      // don't allow user to delete more than dynamo transaction allows
      if (deletedLocations.length >= this.max_patch_or_delete_items) {
        this.$toast.error(
          `Cannot delete more than ${this.max_patch_or_delete_items} drop-off locations at once. Please save current changes before continuing.`,
        );
      } else {
        this.dialogRemoveConfirm = true;
        this.locationToRemove = location;
      }
    },
    confirmRemove() {
      const updated_list = this.dropOffLocations.reduce((acc, curr) => {
        if (curr.id === this.locationToRemove.id) {
          // if action is to create
          // remove location from the array
          if (curr.action === 'CREATE') {
            return acc;
          }
          // update the action to delete
          // will remove from db
          return [
            ...acc,
            {
              ...curr,
              action: 'DELETE',
            },
          ];
        }
        return [...acc, curr];
      }, []);
      this.$emit('update:dropOffLocations', updated_list);
      this.closeRemoveModal();
    },
  },
};
</script>

<style scoped>
span.foodlocker {
  width: 72px;
  height: 32px;
  border-radius: 4px;
  background-color: #0a43a7;
  padding: 2px 10px 0 10px;
}
table.v-table tbody td,
table.v-table tbody th {
  height: 60px;
}
.v-datatable thead th.column.sortable i {
  position: relative;
  bottom: 4px;
}
.v-datatable thead th.column.sortable.desc.active i {
  transform: rotate(-180deg) translate(0, -5px);
}
.p-remove-spacing {
  margin: 0;
  padding: 0 40px 20px;
}
</style>
